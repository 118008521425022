import { makeStyles } from '@mui/styles'
import { styled } from 'styled-components'
import { theme } from 'styles/Theme/theme'

const useStyles = makeStyles({
  brandContainer: {
    padding: '0px 54px 39px 54px !important',
  },

  brandCard: {
    cursor: 'pointer',
    height: '171px !important',
    width: '171px !important',
    minWidth: '171px !important',
    borderRadius: '24.5px !important',
    background: '#F5F5F5 !important',
    display: 'flex',
    boxShadow: 'none !important',
    margin: '10px auto',
  },
  brandImage: {
    margin: 'auto !important',
    maxWidth: '100%',
    textAlign: 'center',
  },
  brandNameTypo: {
    textTransform: 'uppercase',
    fontWeight: '400 !important',
    marginTop: '13px !important',
    textAlign: 'center',
    paddingLeft: '10px',
    paddingRight: '10px',
    fontSize: '14px',
    color: '#115469',
  },
  '@media (max-width: 600px)': {
    brandCard: {
      height: '130px !important',
      width: '130px !important',
      minWidth: '120px !important',
      borderRadius: '16px !important',
      margin: '5px',
    },
  },
})

export default useStyles

export const BrandItemContainer = styled.div`
  color: ${({ theme }) => theme.secondaryColor};
  margin-bottom: 20px;

`
export const StoreCardContainer = styled.div`
  width: 100%;
  margin: 20px auto;
  @media screen and (max-width: 1050px) {
    max-width: 800px;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
  }
`
export const StoresCard = styled.div`
  cursor: pointer;
  height: 171px;
  width: 171px;
  min-width: 171px;
  border-radius: 24.5px;
  overflow: hidden;
  background: ${theme.TERTIARYBACKGROUNDCOLOR};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
  @media (max-width: 450px) {
    height: 130px;
    width: 142px;
    min-width: 142px;
    margin: 0;
  }
`
export const StoresImageWrapper = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    margin: auto;
    max-width: 100%;
    text-align: center;
  }
`
export const BrandName = styled.p`
  text-transform: uppercase;
  font-weight: 400;
  margin-top: 13px;
  text-align: center;
  padding-left: 57px !important;
  padding-right: 10px;
  font-size: 14px;
  color: ${theme.PRIMARYCOLOR};
  width: 180px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 450px) {
    width: 130px;
    padding-left: 11px;
  }
`
export const NoDataWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 50px;
`
