import React from 'react'
import BannerSection from 'views/BannerSection'
import ContactForm from 'views/ContactUsForm'
import BannerImage1 from 'assets/images/HpImages/contact-image.JPG'
import {
  Title,
  MainWrapper,
  ContactUsWrapper,
  ContentWrapper,
  ContactUsContent,
} from 'styles/FooterLinks/ContactUsPage'

const ContactUs = () => {
  return (
    <MainWrapper>
      <BannerSection title="Contact Us" bannerImage={BannerImage1} />
      <ContactUsWrapper>
        <ContentWrapper>
          <div>
            <Title>We&quot;d love to hear from you! Let&quot;s get in touch</Title>
            <ContactUsContent>
              If you have any questions, need assistance, or want to learn more about our products, we&quot;re here to
              help. At HimIra, we prioritize your satisfaction and are committed to providing you with excellent
              customer service.
            </ContactUsContent>
          </div>
          <div>
            <Title>Customer Support Hours</Title>
            <ContactUsContent>
              Our customer service team is available from Monday to Saturday, 11 am to 7 pm, to assist you with all your
              inquiries. Please note that our support channels are closed on Sundays, festivals, and national holidays.
            </ContactUsContent>
          </div>
          <div>
            <Title>How to Reach Us</Title>
            <ContactUsContent>
              You can easily reach us by filling out the contact form on this page. We&quot;re also available via email
              at <b>himira.hp@gmail.com</b> Whether you have a question about an order, need help with a return, or just
              want to provide feedback, we&quot;re here for you.
            </ContactUsContent>
          </div>
          <div>
            <Title>Your Feedback Matters</Title>
            <ContactUsContent>
              We value your feedback and are always looking for ways to improve. Please don&quot;t hesitate to share
              your thoughts with us. Your input helps us serve you better.
            </ContactUsContent>
          </div>
          <div>
            <Title>Thank You for Choosing HimIra</Title>
            <ContactUsContent>
              We appreciate your support and look forward to assisting you. Please take a moment to fill out the contact
              form below, and we&quot;ll get back to you as soon as possible.
            </ContactUsContent>
          </div>
        </ContentWrapper>
        <ContactForm />
      </ContactUsWrapper>
    </MainWrapper>
  )
}

export default ContactUs
