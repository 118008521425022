import styled from 'styled-components'
import paper from 'assets/images/HpImages/paperImage.png'
import Trees from 'assets/images/HpImages/trees.png'
import flower from 'assets/images/HpImages/SideFlower.png'
import empowerBackground from 'assets/images/HpImages/empowerBackground-image.png'
import mobileEmpowerBackground from 'assets/images/HpImages/mobileEmpower-background.png'
import { theme } from 'styles/Theme/theme'

export const Wrapper = styled.div`
  background: #fffff9;
  overflow: hidden;
  margin-top: 55px;
  @media (max-width: 500px) {
    margin-top: 56px;

  }
`
export const PincodeButtonWrapper = styled.div`
  button {
    height: 56px;
  }
`
export const TextOverlay = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;

  h5 {
    font-family: Playfair Display;
    font-size: 52px;
    font-weight: 600;
    text-align: center;
  }
  p {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
  }
`
export const HimachalWrapper = styled.div``
export const Text = styled.p`
  font-size: 10.55px;
  font-weight: 500;
  line-height: 17.58px;
  text-align: center;
`
/////////////////////
export const PaperBackground = styled.div`
  width: 100%;
  height: 700px;
  background-image: url(${paper});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`
/////////////
export const TreeBackground = styled.div`
  margin-top: 30px;
  width: 100%;
  height: 487px;
  background-image: url(${Trees});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`
////////////////
export const ReviewImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
`

export const EmpowerWrapper = styled.div`
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  padding: 40px 0;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`
export const Content = styled.p`
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  color: ${theme.HPSECONDARYCOLOR};
  max-width: 558px;
  width: 100%;
  text-align: center;
  font-family: Georgia;

  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 28px;
    text-align: center;
  }
`
export const EmpowerContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${empowerBackground});
  background-repeat: no-repeat;
  width: 100%;
  @media (max-width: 767px) {
    width: 100%;
    height: 45vh;
    background-image: url(${mobileEmpowerBackground});
  }
`
export const EmpowerImageWrapper = styled.div`
  position: relative;
  z-index: 0;
  @media (max-width: 767px) {
    img {
      height: 385px;
      width: 473px;
    }
  }
`
export const TopCloudsWrapper = styled.div`
  position: absolute;
  transform: translate(-75px, -100px);
  @media (max-width: 767px) {
    display: none;
  }
`
export const BottomCloudsWrapper = styled.div`
  position: absolute;
  transform: translate(104px, 262px);
  z-index: 1;
  @media (max-width: 767px) {
    display: none;
  }
`
export const SideFlowerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${flower}) no-repeat top left;
  background-size: auto;
  z-index: 1; /* Ensure leaves are below content */
`

export const SliderWrapper = styled.div``

export const FooterImageWrapper = styled.div`
  width: 100vw;
  img {
    max-width: 100vw;
    width: 100%;
  }
`
