import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 50px;
  margin: 0 100px 55px;
  @media (max-width: 450px) {
    margin: 90px 24px 70px 24px;
  }
`
export const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  @media (max-width: 767px) {
    padding: 0;
  }
`
export const Content = styled.div`
  font-weight: 700;
  font-size: 42px;
  line-height: 51.2px;
  color: ${theme.HPSECONDARYCOLOR};
  max-width: 417px;
  width: 100%;
  @media (max-width: 450px) {
    font-size: 24px;
    line-height: 29.26px;
  }
`
export const ButtonWrapper = styled.div`
  button {
    display: flex;
    gap: 6px;
    max-width: 235px;
    width: 100%;
  }
  @media (max-width: 767px) {
    button {
      max-width: unset;
    }
  }
`
export const ProductImageSection = styled.div`
  display: flex;
  gap: 16px;
  @media (max-width: 767px) {
    justify-content: center;
  }
`
export const ImageSection = styled.div`
  img {
    height: 400px;
    width: 335px;
    object-fit: contain;
  }
  @media (max-width: 450px) {
    display: none;
  }
`
