import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const ReturnOrderModalWrapper = styled.div`
  max-width: 700px;
  min-width: 700px;
  width: 100%;
  @media (max-width: 450px) {
    max-width: unset;
    min-width: unset;
  }
`
export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  svg {
    cursor: pointer;
  }
`

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px solid ${theme.SECONDARYBORDERCOLOR};
`
export const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 300px;
  overflow: auto;
  @media (max-width: 450px) {
    max-height: unset;
  }
`
export const ReturnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
export const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  @media (max-width: 450px) {
    flex-direction: column;
  }
`
export const ProductImageWrapper = styled.div`
  width: 100px;
  height: 80px;
  img {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 450px) {
    img {
      width: 100px;
      height: 80px;
    }
  }
`
export const ProductDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
export const ProductName = styled.div`
  font-size: 16px;
  color: ${theme.PRIMARYGREYCOLOR};
`
export const DetailContainer = styled.div``
export const QuantityWrapper = styled.div`
  font-size: 16px;
  color: ${theme.PRIMARYGREYCOLOR};
`
export const ProductImagesWrapper = styled.div``
export const SupportedImageFormats = styled.div`
  color: ${theme.GREYCOLOR};
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
`
export const CountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`
export const OptionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`
export const ProductPrice = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${theme.PRIMARYTEXTCOLORDARK};
`
export const ReturnReasonWrapper = styled.div`
  .reason-wrapper {
    height: 36px !important;
    font-size: 12px !important;
  }

  .dropdown-menu.return-dropdown {
    width: 100%;
    max-height: 97px;
    overflow: auto;
    margin: 295px 0 !important;
  }
`
export const ReasonTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: ${theme.PRIMARYGREYCOLOR};
  margin: 5px;
`
export const SelectReasonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 6px 4px;
  background-color: transparent;
  border: 1px solid ${theme.GREYCOLOR};
  border-radius: 10px;
  margin-top: 4px;
`
export const PlaceholderText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: ${theme.PRIMARYGREYCOLOR};
  margin: 0;
`
export const QuantityCountWrapper = styled.div`
  width: 90px;
  height: 30px;
  background: ${theme.PRIMARYBORDERCOLORLIGHT};
  border: 1px solid ${theme.DARKBLUE};
  color: ${theme.DARKBLUE};
  border-radius: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`
export const ProductQuantityCount = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: ${theme.DARKBLUE};
`

export const RefundWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  .refund-checkbox {
    border: none;
    background: transparent;
    width: 25px;
  }
`
export const RefundText = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: ${theme.PRIMARYGREYCOLOR};
`
export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 16px 4px 0 16px;
  width: 100%;
  button {
    max-width: 200px;
  }
`
export const MinusWrapper = styled.div`
  height: 100%;
  width: 100%;
`
export const PlusWrapper = styled.div`
  height: 100%;
  width: 100%;
  cursor: pointer;
`
