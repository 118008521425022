import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'
export const NavbarWrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
`
export const NavbarContainer = styled.div`
  /* height: 80px; */
  background: ${(props) => (props.isHomepage ? '' : 'transparent')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 100px 12px 100px;
  position: ${(props) => (props.isHomepage ? 'absolute' : '')};
  top: ${(props) => (props.isHomepage ? '34px' : '')};
  z-index: 1;
  width: ${(props) => (props.isHomepage ? '100%' : '')};
  box-shadow: ${(props) => (props.isHomepage ? 'none' : '-8px 9px 35px -31px rgb(0 0 0 / 76%)')};
  background: #fff;
  z-index: 4;

  @media (max-width: 767px) {
    padding: 10px 16px;
  }
`
export const NavbarUpperSection = styled.div`
  height: 36px;
  background: ${theme.HPGRADIANTCOLOR};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 100px;
  @media (max-width: 767px) {
    padding: 0 16px;
  }
`
export const TextWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  @media (max-width: 450px) {
    justify-content: unset;
  }
`
export const Text = styled.h4`
  color: ${theme.HPTEXTCOLORSECONDARYCOLORY};
  font-family: Besley;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;
  @media (max-width: 450px) {
    font-size: 12px;
  }
`
export const DeliveryText = styled.h4`
  color: ${theme.HPTEXTCOLORSECONDARYCOLORY};
  font-family: Besley;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;
  @media (max-width: 767px) {
    display: none;
  }
`
export const LogoWrapper = styled.div`
  cursor: pointer;
  img {
    width: 101px;
  }
  @media (max-width: 767px) {
    display: none;
  }
`
export const MobileWrapper = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    svg {
      path {
        fill: #000;
        cursor: pointer;
      }
    }
  }
`
export const MobileLogoWrapper = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: flex;
    cursor: pointer;
    img {
      max-width: 86px;
    }
  }
`
export const NavbarContentWrapper = styled.div`
  display: flex;
  gap: 25px;
  align-items: center;
  a {
    text-decoration: none;
  }
  @media (max-width: 920px) {
    gap: 20px;
  }
  @media (max-width: 767px) {
    display: none;
  }
`
export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  .search {
    ::before {
      left: 8px !important;
      top: 38px !important;
      padding: 0 0 5px 0 !important;
    }
    ::after {
      border-bottom: none !important;
    }
  }
  .search-icon {
    path {
      fill: #979797;
    }
  }
  @media (max-width: 500px) {
    gap: 12px;
    .search {
      ::before {
        left: 10px !important;
        top: 28px !important;
      }
    }
  }
`
export const MenuItem = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  /* padding: 0 10px 12px; */
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? theme.HPPRIMARYCOLORDARK : theme.HPTEXTCOLORDARK)};
  border-bottom: ${({ isActive }) => (isActive ? `2px solid ${theme.HPPRIMARYCOLORDARK}` : 'none')};
  &:hover {
    color: ${theme.HPPRIMARYCOLORDARK};
  }
`
export const IconWrapper = styled.div`
  cursor: pointer;
`
export const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  background: ${theme.HPSECONDARYCOLOR};
  min-height: 100%;
  width: 100%;
  max-width: 200px;
  min-width: 200px;
  display: grid;
  align-content: flex-start;
  gap: 20px;
  padding: 20px;
`
export const UserWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`
export const ImageWrapper = styled.div`
  width: 68px;
  height: 68px;
  img {
    width: 68px;
    height: 68px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
  }
`
export const UserName = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.SECONDARYTEXTCOLOR};
`
export const MenuItemSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 30px;
`
export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  cursor: pointer;
  a {
    color: ${theme.SECONDARYTEXTCOLOR};
    text-decoration: none;
  }
`
export const ListItems = styled.div`
  display: flex;
  gap: 10px;
`
export const IconsWrapper = styled.div`
  width: 30px;
  height: 30px;
`

// export const PincodeWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   gap: 5px;
//   cursor: pointer;
//   svg {
//     width: 16px;
//   }
// `
export const PincodeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  position: absolute;
  right: 100px; /* Aligns it to the right */
  svg {
    width: 16px;
  }
  @media (max-width: 767px) {
    right: 16px; /* Responsive alignment for smaller screens */
  }
`
export const PincodeText = styled.p`
  margin-bottom: 0;
  color: ${theme.HPTEXTCOLORSECONDARYCOLORY};
  font-size: 16px;
  font-family: Besley;
  @media (max-width: 450px) {
    font-size: 12px;
  }
`
export const SelectPincodeText = styled.p`
  margin-bottom: 0;
  color: ${theme.HPTEXTCOLORSECONDARYCOLORY};
  font-size: 16px;
  font-family: Besley;
  @media (max-width: 450px) {
    font-size: 12px;
  }
`
export const ListOptions = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.SECONDARYTEXTCOLOR};
`
export const MenuItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 200px);
`
