import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'
export const BannerWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 50px;
`
export const BannerTitle = styled.h1`
  font-size: 60px;
  font-weight: 600;
  color: ${theme.HPTEXTCOLORSECONDARYCOLORY};
  @media (max-width: 767px) {
    font-size: 48px;
    text-align: center;
  }
`
export const ImageWrapper = styled.div`
  position: relative;
  width: 100vw;
  img {
    width: 100vw;
    height: 530px;
    object-fit: cover;
  }
  @media (max-width: 767px) {
    img {
      height: 300px;
    }
  }
`
export const BreadcrumbWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 155px;
  margin: 0 70px;
  @media (max-width: 767px) {
    top: 110px;
    width: unset;
    margin: 0 20px;
  }
`
