import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  @media (max-width: 767px) {
    gap: 32px;
  }
`
export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 767px) {
    justify-content: center;
  }
`
export const LeftImageWrapper = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`
export const HeadingSection = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  text-align: center;
  gap: 12px;
  @media (max-width: 767px) {
    margin-top: 34px;
    gap: 12px;
  }
`
export const MainHeading = styled.h5`
  font-weight: 700;
  font-size: 42px;
  line-height: 51.2px;
  color: ${theme.HPPRIMARYTEXTCOLORDARK};
  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 29.26px;
    text-align: center;
  }
`
export const SubHeading = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.HPTEXTCOLORLIGHT};
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    max-width: 184px;
    width: 100%;
    margin: 0 auto !important;
  }
`
export const RightImageWrapper = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`

// export const MobileHeadingSection = styled.div`
//   display: none;
//   @media (max-width: 767px) {
//     margin: 54px auto 0;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     text-align: center;
//     max-width: 252px;
//     width: 100%;
//     gap: 12px;
//   }
// `
export const ProductsContainer = styled.div`
  margin: 0 100px;
  z-index: 1;
  position: absolute;
  width: 89%;
  top: 80px;
  .slick-slider {
    width: 100%;
  }
  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    gap: 7px;
    width: 100%;
  }
  .slick-prev:before {
    display: none !important;
  }
  .slick-next:before {
    display: none !important;
  }
  .slick-prev {
    left: 0 !important;
  }
  .slick-next {
    right: 1px !important;
  }
  .slick-list {
    margin-left: 20px;
  }
  .slick-next:hover {
    background-color: ${theme.HPPRIMARYCOLOR};
  }
  .slick-prev:hover {
    background-color: ${theme.HPPRIMARYCOLOR};
  }
  .slick-list {
    margin-right: 86px;
    margin-left: 70px;
  }
  .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: ${theme.HPSECONDARYCOLOR};
    font-size: 10px;
  }
  .slick-dots li button:before {
    font-size: 10px;
  }
  @media (max-width: 1600px) {
    .slick-track {
      gap: 10px;
    }
    .slick-list {
      margin-left: 60px;
      margin-left: 68px;
    }
  }
  @media (max-width: 1500px) {
    .slick-track {
      display: flex;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media (max-width: 767px) {
    margin: 0 24px;
    position: unset;
    .slick-list {
      margin-right: 0;
      margin-left: 0;
    }
    .slick-initialized .slick-slide {
      width: unset !important;
    }
    .slick-track {
      justify-content: start !important;
    }
    .slick-dots {
      bottom: 36px !important;
    }
  }
`
export const CardWrapper = styled.div`
  height: 357px;
  width: 295px;
  border-radius: 15px;
  margin: 10px;
  overflow: hidden;
  @media (max-width: 767px) {
    width: 200px;
    height: 252px;
  }
`
export const CardSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 295px;
  border-radius: 15px;
  background: ${theme.HPPRIMARYLIGHTCOLOR};
  height: 375px;
  padding: 30px;
  gap: 32px;
  @media (max-width: 767px) {
    width: 200px;
    height: 252px;
  }
`
export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 400px;
  width: 100%;
  img {
    width: 100%;
    height: auto;
    cursor: pointer;
    border-radius: 8px;
    overflow: hidden;
  }
  @media (max-width: 450px) {
    /* img {
      width: 135.59px;
      height: 135.59px;
      margin: 0 auto;
    } */
  }
`
export const ProductTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${theme.HPTEXTCOLORLIGHT};
  text-align: center;
  max-width: 250px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  @media (max-width: 767px) {
    font-size: 16.27px;
    line-height: 21.69px;
  }
`
export const IconButton = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background-color: ${theme.HPPRIMARYCOLOR};
  cursor: pointer;
  z-index: 1;
  svg {
    path {
      fill: ${theme.HPTEXTCOLORSECONDARYCOLORY};
      stroke: ${theme.HPTEXTCOLORSECONDARYCOLORY};
    }
  }
  @media (max-width: 767px) {
    display: none;
  }
`
