import React, { useState, useEffect, useRef, useContext } from 'react'
import ReactGA from 'react-ga4'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import useStyles, {
  BillingContainer,
  DetailsContainer,
  SummaryContainer,
  ProductContainer,
  ItemsDetailWrapper,
  ItemNameWrapper,
  ItemsWrapper,
  ItemsContainer,
  DeliveryModeItemsWrapper,
  ItemImageWrapper,
  DeliveryModeImageWrapper,
  ProductDeliveryModeContainer,
  SummarCardWrapper,
  HeadingWrapper,
  SubtotalContainer,
  DeliveryChargesWrapper,
  OrderTotalContainer,
  ProceedButtonWrapper,
  ChargesTitle,
} from './style'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Redirect, useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'
import { constructQouteObject } from '../../api/utils/constructRequestObject'
import styles from '../../styles/cart/cartView.module.scss'
import { payment_methods } from '../../constants/payment-methods'
import { getValueFromCookie, removeCookie } from '../../utils/cookies'
import { getSelectCall, postCheckoutCall } from '../../api/axios'
import useCancellablePromise from '../../api/cancelRequest'
import { SSE_TIMEOUT } from '../../constants/sse-waiting-time'
import { ToastContext } from '../../context/toastContext'
import { toast_actions, toast_types } from '../shared/toast/utils/toast'
import { CartContext } from '../../context/cartContext'
import RazorpayPayment from '../../views/payment-integration'
import AddressForm from './addressForm/addressForm'
import StepPaymentContent from './stepPayment/stepPaymentContent'
import Spinner from 'components/Loader'
import ProductImage from 'assets/images/no_image_found.png'
import {
  AddressDetail,
  DeliveryAddressTitle,
  DeliveryMode,
  DeliveryModeSection,
  DeliveryModeWrapper,
  RadioWrapper,
} from 'styles/orderModal'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import Cookies from 'js-cookie'
import { formatIndianRupees } from 'helper'

const Checkout = () => {
  const classes = useStyles()
  const history = useHistory()
  const [cartItems, setCartItems] = useState([])
  const [updatedCartItems, setUpdatedCartItems] = useState([])

  const [apiCallSuccess, setApiCallSuccess] = useState(false)
  const [orderId, setOrderId] = useState('')
  const [productsQuote, setProductsQuote] = useState({
    providers: [],
    isError: false,
    total_payable: 0,
  })
  const [initLoading, setInitLoading] = useState(false)
  const [razorpayPaymentId, setRazorpayPaymentId] = useState('')
  const [activePaymentMethod, setActivePaymentMethod] = useState('')
  const [confirmOrderLoading, setConfirmOrderLoading] = useState(false)
  const responseRef = useRef([])
  const eventTimeOutRef = useRef([])
  const [eventData, setEventData] = useState([])
  const dispatch = useContext(ToastContext)
  const { fetchCartItems } = useContext(CartContext)
  const [quoteItemInProcessing, setQuoteItemInProcessing] = useState(null)
  const [selectedFulfillments, setSelectedFulfillments] = useState({})
  const [initOrder, setInitOrder] = useState(false)
  const [deliveryModes, setDeliveryModes] = useState([{}])
  const [checkedState, setCheckedState] = useState({})

  // HOOKS
  const { cancellablePromise } = useCancellablePromise()

  const resetCartItems = () => {
    const cartItemsData = JSON.parse(localStorage.getItem('cartItems'))
    const updatedCartItemsData = JSON.parse(localStorage.getItem('updatedCartItems'))
    setCartItems(cartItemsData)
    setSelectedFulfillments({})
    // setNewSelectFulfillments({})
    setUpdatedCartItems(updatedCartItemsData)
  }

  function dispatchToast(type, message) {
    dispatch({
      type: toast_actions.ADD_TOAST,
      payload: {
        id: Math.floor(Math.random() * 100),
        type,
        message,
      },
    })
  }

  useEffect(() => {
    resetCartItems()
  }, [])

  useEffect(() => {
    let timeout
    const duration = moment.duration(updatedCartItems[0]?.message.quote.quote.ttl)

    if (updatedCartItems[0]?.message.quote.quote.ttl) {
      timeout = setTimeout(() => {
        history.push('/cart')
        dispatchToast(toast_types.error, 'Request Timed out, please try again!')
      }, duration.asMilliseconds())

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [updatedCartItems[0]?.message?.quote?.quote?.ttl])

  useEffect(() => {
    try {
      if (updatedCartItems.length > 0) {
        // fetch request object length and compare it with the response length

        const cartList = JSON.parse(JSON.stringify(updatedCartItems))
        // check if any one order contains error
        let total_payable = 0
        let isAnyError = false
        let quotes = updatedCartItems?.map((item) => {
          let { message, error } = item
          let provider_payable = 0
          const provider = {
            products: [],
            total_payable: 0,
            name: '',
            error: null,
          }
          // else generate quote of it
          if (message) {
            // message = m2;

            if (message?.quote?.quote?.price?.value) {
              provider_payable += Number(message?.quote?.quote?.price?.value)
            }

            const breakup = message?.quote?.quote?.breakup
            const provided_by = message?.quote?.provider?.descriptor?.name
            provider.name = provided_by
            let uuid = 0
            const all_items = breakup?.map((break_up_item) => {
              const cartIndex = cartList?.findIndex((one) => one.id === break_up_item['@ondc/org/item_id'])
              const cartItem = cartIndex > -1 ? cartList[cartIndex] : null
              let findItemFromCartItems = null
              let isCustimization = false
              if (break_up_item?.item?.tags) {
                const findTag = break_up_item?.item?.tags.find((tag) => tag.code === 'type')
                if (findTag) {
                  const findCust = findTag.list.find((listItem) => listItem.value === 'customization')
                  if (findCust) {
                    isCustimization = true
                  }
                }
              }

              cartItems.forEach((ci) => {
                if (isCustimization) {
                  const cc = ci?.item?.customisations || []
                  cc.forEach((i) => {
                    if (i.local_id === break_up_item['@ondc/org/item_id']) {
                      findItemFromCartItems = i
                    }
                  })
                } else {
                  if (ci?.item?.local_id === break_up_item['@ondc/org/item_id']) {
                    findItemFromCartItems = ci?.item
                  }
                }
              })
              let cartQuantity = findItemFromCartItems
                ? findItemFromCartItems?.quantity?.count
                : cartItem
                  ? cartItem?.quantity?.count
                  : 0
              let quantity = break_up_item['@ondc/org/item_quantity']
                ? break_up_item['@ondc/org/item_quantity']['count']
                : 0

              let textClass = ''
              let quantityMessage = ''
              let isError = false
              if (quantity === 0) {
                if (break_up_item['@ondc/org/title_type'] === 'item') {
                  textClass = 'text-error'
                  quantityMessage = 'Out of stock'
                  isError = true

                  if (cartIndex > -1) {
                    cartList.splice(cartIndex, 1)
                  }
                }
              } else if (quantity !== cartQuantity) {
                textClass = break_up_item['@ondc/org/title_type'] === 'item' ? 'text-amber' : ''
                quantityMessage = `Quantity: ${quantity}/${cartQuantity}`
                isError = true

                if (cartItem) {
                  cartItem.quantity.count = quantity
                }
              } else {
                quantityMessage = `Quantity: ${quantity}`
              }

              if (error && error.code === '30009') {
                cartList.splice(cartIndex, 1)
              }

              uuid = uuid + 1
              return {
                id: break_up_item['@ondc/org/item_id'],
                title: break_up_item?.title,
                title_type: break_up_item['@ondc/org/title_type'],
                isCustomization: isItemCustomization(break_up_item?.item?.tags),
                isDelivery: break_up_item['@ondc/org/title_type'] === 'delivery',
                parent_item_id: break_up_item?.item?.parent_item_id,
                price: Number(break_up_item.price?.value)?.toFixed(2),
                cartQuantity,
                quantity,
                provided_by,
                textClass,
                quantityMessage,
                uuid: uuid,
                isError,
                errorCode: error?.code || '',
              }
            })

            let items = {}
            let delivery = {}
            let outOfStock = []
            let errorCode = ''
            let selected_fulfillments = selectedFulfillments

            updatedCartItems.forEach((item) => {
              item?.message?.quote.items.forEach((item) => {
                selected_fulfillments[item.id] = item.fulfillment_id
              })
            })

            setSelectedFulfillments(selected_fulfillments)

            let selected_fulfillment_ids = Object.values(selected_fulfillments)

            all_items.forEach((item) => {
              errorCode = item.errorCode
              setQuoteItemInProcessing(item.id)
              if (item.isError) {
                outOfStock.push(item)
                isAnyError = true
              }

              // for type item
              if (item.title_type === 'item' && !item.isCustomization) {
                let key = item.parent_item_id || item.id
                let price = {
                  title: item.quantity + ' * Base Price',
                  value: item.price,
                }
                let prev_item_data = items[key]
                let addition_item_data = { title: item.title, price: price }
                items[key] = { ...prev_item_data, ...addition_item_data }
              }

              if (
                item.title_type === 'tax' &&
                !item.isCustomization &&
                !selected_fulfillment_ids.includes(item.id)
                // item.id !== selected_fulfillments
              ) {
                let key = item.parent_item_id || item.id
                items[key] = items[key] || {}
                items[key]['tax'] = {
                  title: item.title,
                  value: item.price,
                }
              }

              if (item.title_type === 'discount' && !item.isCustomization) {
                let key = item.parent_item_id || item.id
                items[key] = items[key] || {}
                items[key]['discount'] = {
                  title: item.title,
                  value: item.price,
                }
              }

              //for customizations
              if (item.title_type === 'item' && item.isCustomization) {
                let key = item.parent_item_id
                items[key]['customizations'] = items[key]['customizations'] || {}
                let existing_data = items[key]['customizations'][item.id] || {}
                let customisation_details = {
                  title: item.title,
                  price: {
                    title: item.quantity + ' * Base Price',
                    value: item.price,
                  },
                  quantityMessage: item.quantityMessage,
                  textClass: item.textClass,
                  quantity: item.quantity,
                  cartQuantity: item.cartQuantity,
                }
                items[key]['customizations'][item.id] = {
                  ...existing_data,
                  ...customisation_details,
                }
              }

              if (item.title_type === 'tax' && item.isCustomization) {
                let key = item.parent_item_id
                items[key]['customizations'] = items[key]['customizations'] || {}
                items[key]['customizations'][item.id] = items[key]['customizations'][item.id] || {}
                items[key]['customizations'][item.id]['tax'] = {
                  title: item.title,
                  value: item.price,
                }
              }

              if (item.title_type === 'discount' && item.isCustomization) {
                let key = item.parent_item_id
                items[key]['customizations'] = items[key]['customizations'] || {}
                items[key]['customizations'][item.id] = items[key]['customizations'][item.id] || {}
                items[key]['customizations'][item.id]['discount'] = {
                  title: item.title,
                  value: item.price,
                }
              }

              //for delivery
              if (item.title_type === 'delivery' && selected_fulfillment_ids.includes(item.id)) {
                delivery['delivery'] = {
                  title: item.title,
                  value: item.price,
                }
              }

              if (item.title_type === 'discount_f' && selected_fulfillment_ids.includes(item.id)) {
                delivery['discount'] = {
                  title: item.title,
                  value: item.price,
                }
              }

              if (
                (item.title_type === 'tax_f' || item.title_type === 'tax') &&
                selected_fulfillment_ids.includes(item.id)
              ) {
                delivery['tax'] = {
                  title: item.title,
                  value: item.price,
                }
              }

              if (item.title_type === 'packing' && selected_fulfillment_ids.includes(item.id)) {
                delivery['packing'] = {
                  title: item.title,
                  value: item.price,
                }
              }

              if (item.title_type === 'discount') {
                if (item.isCustomization) {
                  let id = item.parent_item_id
                  return id // Need to check
                } else {
                  let id = item.id
                  items[id]['discount'] = {
                    title: item.title,
                    value: item.price,
                  }
                }
              }

              if (item.title_type === 'misc' && selected_fulfillment_ids.includes(item.id)) {
                delivery['misc'] = {
                  title: item.title,
                  value: item.price,
                }
              }
            })
            setQuoteItemInProcessing(null)
            provider.items = items
            provider.delivery = delivery
            provider.outOfStock = outOfStock
            provider.errorCode = errorCode || ''
            if (errorCode !== '') {
              isAnyError = true
            }
          }

          if (error) {
            provider.error = error.message
          }

          total_payable += provider_payable
          provider.total_payable = provider_payable
          return provider
        })

        setProductsQuote({
          providers: quotes,
          isError: isAnyError,
          total_payable: total_payable.toFixed(2),
        })
      }
    } catch (err) {
      showQuoteError()
    }
  }, [updatedCartItems, selectedFulfillments])

  const showQuoteError = () => {
    let msg = ''
    if (quoteItemInProcessing) {
      msg = `Looks like Quote mapping for item: ${quoteItemInProcessing} is invalid! Please check!`
    } else {
      msg = 'Seems like issue with quote processing! Please confirm first if quote is valid!'
    }

    dispatchError(msg)
  }

  const isItemCustomization = (tags) => {
    let isCustomization = false
    tags?.forEach((tag) => {
      if (tag.code === 'type') {
        tag.list.forEach((listOption) => {
          if (listOption.code === 'type' && listOption.value == 'customization') {
            isCustomization = true
            return true
          }
        })
      }
    })
    return isCustomization
  }

  useEffect(() => {
    if (responseRef.current.length > 0) {
      // setConfirmOrderLoading(false)
      // fetch request object length and compare it with the response length
      const { successOrderIds } = JSON.parse(localStorage.getItem('checkout_details') || '{}')
      let c = cartItems.map((item) => {
        return item.item
      })
      const requestObject = constructQouteObject(
        c.filter(({ provider }) => successOrderIds.includes(provider.local_id.toString())),
      )
      if (responseRef.current.length === requestObject.length) {
        // redirect to order listing page.
        // remove parent_order_id, search_context from cookies
        removeCookie('transaction_id')
        removeCookie('parent_order_id')
        removeCookie('billing_address')
        localStorage.removeItem('checkout_details')
        removeCookie('parent_and_transaction_id_map')
        localStorage.setItem('transaction_id', uuidv4())
        setCartItems([])
        history.replace('/profile')
      }
    }
  }, [eventData])

  // function to dispatch error
  function dispatchError(message) {
    dispatch({
      type: toast_actions.ADD_TOAST,
      payload: {
        id: Math.floor(Math.random() * 100),
        type: toast_types.error,
        message,
      },
    })
  }

  const handlePaymentSuccess = (razorpay_payment_id) => {
    setRazorpayPaymentId(razorpay_payment_id)
  }

  // on confirm order Api
  const onConfirmOrder = async (message_id, itemsLength) => {
    try {
      const data = await cancellablePromise(getSelectCall(`clientApis/v2/on_confirm_order?messageIds=${message_id}`))
      responseRef.current = [...responseRef.current, data[0]]
      setEventData((eventData) => [...eventData, data[0]])
      fetchCartItems()

      if (data && responseRef.current.length === itemsLength) {
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.success,
            message: 'Order placed successfully.',
          },
        })

        if (responseRef.current.length === itemsLength) {
          setConfirmOrderLoading(false)
          history.push('/profile')
        }
      }
    } catch (err) {
      dispatchError(err?.response?.data?.error?.message)
      setConfirmOrderLoading(false)
    }
  }

  function onConfirm(message_id, itemsLength) {
    eventTimeOutRef.current = []
    const token = getValueFromCookie('token')
    let header = {
      headers: {
        ...(token && {
          Authorization: `Bearer ${token}`,
        }),
      },
    }

    message_id.forEach((id) => {
      let es = new window.EventSourcePolyfill(
        `${process.env.REACT_APP_BASE_URL}clientApis/events/v2?messageId=${id}`,
        header,
      )

      es.addEventListener('on_confirm', (e) => {
        try {
          const { messageId } = JSON.parse(e.data)
          onConfirmOrder(messageId, itemsLength)

          // Close the event connection as soon as the response is received
          es.close()

          const eventTimeout = eventTimeOutRef.current.find((item) => item.eventSource === es)
          if (eventTimeout) {
            clearTimeout(eventTimeout.timer)
            eventTimeOutRef.current = eventTimeOutRef.current.filter((item) => item.eventSource !== es)
          }
        } catch (error) {
          dispatchError('Seller is not accepting orders.')
          setConfirmOrderLoading(false)
          history.push('/')
        }
      })

      const timer = setTimeout(() => {
        eventTimeOutRef.current.forEach(({ eventSource, timer }) => {
          eventSource.close()
          clearTimeout(timer)
        })

        if (responseRef.current.length < itemsLength) {
          setConfirmOrderLoading(false)
          dispatchError(
            'The seller is not reachable at the moment. Please try again later. Thank you for your patience.',
          )
          history.push('/')
          return
        }
      }, SSE_TIMEOUT)

      eventTimeOutRef.current = [
        ...eventTimeOutRef.current,
        {
          eventSource: es,
          timer,
        },
      ]
    })
  }

  const razorPayOrder = async () => {
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: 'Proceed to Pay',
    })
    const payload = {
      amount: parseFloat(productsQuote?.total_payable).toFixed(2),
      currency: 'INR',
    }
    try {
      const orderData = await cancellablePromise(postCheckoutCall(`/clientApis/v2/razorpay/createOrder`, payload))
      setOrderId(orderData?.data?.id)
      setApiCallSuccess(!apiCallSuccess)
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: error?.response?.data?.message,
        },
      })
    }
  }

  // useEffect(() => {
  //   if (apiCallSuccess) {
  //     razorPayOrder()
  //   }
  // }, [apiCallSuccess])

  useEffect(() => {
    if (razorpayPaymentId) {
      if (activePaymentMethod) {
        const { successOrderIds } = JSON.parse(localStorage.getItem('checkout_details') || '{}')
        setConfirmOrderLoading(true)
        const c = cartItems.map((item) => item.item)

        if (activePaymentMethod === payment_methods.JUSPAY) {
          const request_object = constructQouteObject(
            c.filter(({ product }) => successOrderIds?.includes(product.id.toString())),
          )
          confirmOrder(request_object, payment_methods.JUSPAY) //here
        } else {
          const request_object = constructQouteObject(
            c.filter(({ product }) => successOrderIds?.includes(product.id.toString())),
          )
          confirmOrder(request_object, payment_methods.COD) //here
        }
      } else {
        dispatchError('Please select payment.')
      }
    }
  }, [razorpayPaymentId])

  const getItemProviderId = (item) => {
    const providers = getValueFromCookie('providerIds').split(',')
    let provider = {}
    if (providers.includes(item.provider.local_id)) {
      provider = {
        id: item.provider.local_id,
        locations: item.provider.locations.map((location) => location.local_id),
      }
    }

    return provider
  }

  const confirmOrder = async (items, method) => {
    const transactionData = JSON.parse(localStorage.getItem('transactionData'))
    responseRef.current = []

    const parentOrderIDMap = new Map(JSON.parse(getValueFromCookie('parent_and_transaction_id_map')))
    const { productQuotes: productQuotesForCheckout } = JSON.parse(localStorage.getItem('checkout_details') || '{}')
    const shippingAddress = JSON.parse(getValueFromCookie('delivery_address'))

    try {
      const multiSelleSelectPayload = await Promise.all(
        items?.map(async (ele, index) => {
          try {
            // let parentOrderData = {}
            // if (parentOrderIDMap.has(ele[0]?.provider?.id)) {
            //   parentOrderData = parentOrderIDMap.get(ele[0]?.parent_order_id)
            // } else {
            //   console.warn(`parentOrderData not found for providerId: ${ele[0]?.provider?.id}`)
            // }

            const parent_order_id = Cookies.get('parent_order_id')

            const parentOrderData = parentOrderIDMap?.get(ele[0]?.provider?.id) || { parent_order_id }

            // AddCookie('parent_order_id', data[0]?.context?.parent_order_id)

            const updatedCartItem = updatedCartItems[index]?.message?.quote || {}
            // const productQuote = productQuotesForCheckout[index] || {}
            const matchingProductQuote = productQuotesForCheckout.find(
              (productQuote) => productQuote.transaction_id === parentOrderData?.transaction_id,
            )

            const transactionIds = transactionData
              ?.filter((i) => i?.productId?.some((productId) => ele.some((item) => item?.product?.id === productId)))
              .map((i) => i?.transaction_id)

            // const transactionIds = transactionData
            //   ?.filter((i) => ele.some((item) => item?.provider?.local_id === i?.providerId))
            //   .map((i) => i?.transaction_id)
            // if (productQuote.transaction_id === parentOrderData?.transaction_id) {
            if (matchingProductQuote) {
              let customQuotes = matchingProductQuote.quote

              return {
                context: {
                  domain: ele[0].domain,
                  city: shippingAddress?.address?.areaCode,
                  parent_order_id: parentOrderData.parent_order_id,
                  transaction_id: transactionIds?.[0],
                },
                message: {
                  payment: {
                    paid_amount: Number(customQuotes.price?.value),
                    type: method === payment_methods.COD ? 'ON-FULFILLMENT' : 'ON-ORDER',
                    razorpayPaymentId: razorpayPaymentId, // Assuming this is defined somewhere in the scope
                    '@ondc/org/settlement_basis': updatedCartItem.payment?.['@ondc/org/settlement_basis'] ?? null,
                    '@ondc/org/settlement_window': updatedCartItem.payment?.['@ondc/org/settlement_window'] ?? null,
                    '@ondc/org/withholding_amount': updatedCartItem.payment?.['@ondc/org/withholding_amount'] ?? null,
                  },

                  providers: getItemProviderId(ele[0]),
                },
              }
            } else {
              throw new Error('Transaction ID mismatch.')
            }
          } catch (innerError) {
            dispatch({
              type: toast_actions.ADD_TOAST,
              payload: {
                id: Math.floor(Math.random() * 100),
                type: toast_types.error,
                message:
                  'Unfortunately, we’re experiencing some technical issues while confirming your order. Please bear with us & get back to us sometime.',
              },
            })
            setConfirmOrderLoading(false)
            throw innerError // re-throw to handle in Promise.all
          }
        }),
      )

      try {
        if (!multiSelleSelectPayload) {
          dispatch({
            type: toast_actions.ADD_TOAST,
            payload: {
              id: Math.floor(Math.random() * 100),
              type: toast_types.error,
              message:
                'Unfortunately, we’re experiencing some technical issues while confirming your order. Please bear with us & get back to us sometime.',
            },
          })
        }

        const data = await cancellablePromise(postCheckoutCall('clientApis/v2/confirm_order', multiSelleSelectPayload))
        // let data = []
        const itemsData = data.map((txn) => txn.context?.message_id)
        const isNACK = data.find((item) => item.error && item.code !== '')

        if (isNACK) {
          dispatchError(isNACK.error.message)
        } else {
          onConfirm(itemsData, items.length)
        }
      } catch (apiError) {
        confirmOrderLoading(false)
        dispatchError('Failed to confirm order. Please try again.')
      }
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message:
            err?.response?.data?.message ||
            'Unfortunately, we’re experiencing some technical issues while confirming your order. Please bear with us & get back to us sometime.',
        },
      })
      setConfirmOrderLoading(false)
      history.push('/')
    }
  }

  const renderDeliveryCharges = (data) => {
    try {
      if (!data) return null

      const totalDeliveryCharges = data.reduce((total, order) => {
        const deliveryValue = parseFloat(order.delivery?.delivery?.value || 0)
        return total + deliveryValue
      }, 0)

      const totalMiscCharges = data.reduce((accumulator, currentValue) => {
        // const charge = currentValue?.delivery?.misc?.value ?? 0
        const charge = parseFloat(currentValue.delivery?.misc?.value || 0)
        return accumulator + charge
      }, 0)

      const totalTaxCharges = data.reduce((accumulator, currentValue) => {
        const charge = parseFloat(currentValue.delivery?.tax?.value || 0)
        return accumulator + charge
      }, 0)

      const totalPackingCharges = data.reduce((accumulator, currentValue) => {
        const charge = parseFloat(currentValue.delivery?.packing?.value || 0)
        return accumulator + charge
      }, 0)

      return (
        <div>
          {/* Render Delivery Charges */}
          <div className={classes.summaryDeliveryItemContainer}>
            <ChargesTitle variant="body1" className={classes.summaryDeliveryLabel}>
              Delivery Charges
            </ChargesTitle>
            <Typography variant="body1" className={classes.summaryItemPriceValue}>
              {`₹${formatIndianRupees(totalDeliveryCharges.toFixed(2))}`}
            </Typography>
          </div>

          {/* Render Tax */}
          <div className={classes.summaryDeliveryItemContainer}>
            <ChargesTitle variant="body1" className={classes.summaryDeliveryLabel}>
              Tax
            </ChargesTitle>
            <Typography variant="body1" className={classes.summaryItemPriceValue}>
              {`₹${totalTaxCharges.toFixed(2)}`}
            </Typography>
          </div>

          {/* Render Packing Charges */}
          <div className={classes.summaryDeliveryItemContainer}>
            <ChargesTitle variant="body1" className={classes.summaryDeliveryLabel}>
              Packing Charges
            </ChargesTitle>
            <Typography variant="body1" className={classes.summaryItemPriceValue}>
              {`₹${totalPackingCharges.toFixed(2)}`}
            </Typography>
          </div>

          {/* Render Convenience Fee */}
          <div className={classes.summaryDeliveryItemContainer}>
            <ChargesTitle variant="body1" className={classes.summaryDeliveryLabel}>
              Convenience Fee
            </ChargesTitle>
            <Typography variant="body1" className={classes.summaryItemPriceValue}>
              {`₹${totalMiscCharges.toFixed(2)}`}
            </Typography>
          </div>

          {/* Render subtotal and divider */}
          <div className={classes.summarySubtotalContainer}>
            <Typography variant="body2" className={classes.subTotalLabel}>
              Total
            </Typography>
            <Typography variant="body2" className={classes.subTotalValue}>
              {`₹${getDeliveryTotalAmount(productsQuote?.providers)}`}
            </Typography>
          </div>
          <Box component={'div'} className={classes.orderTotalDivider} />
        </div>
      )
    } catch (error) {
      error
      return null
    }
  }

  const getDeliveryTotalAmount = (providers) => {
    let total = 0
    providers.forEach((provider) => {
      const data = provider?.delivery
      if (data?.delivery) {
        total = total + parseFloat(data?.delivery?.value)
      }

      if (data?.discount) {
        total = total + parseFloat(data?.discount?.value)
      }

      if (data?.tax) {
        total = total + parseFloat(data?.tax?.value)
      }

      if (data?.packing) {
        total = total + parseFloat(data?.packing?.value)
      }

      if (data?.misc) {
        total = total + parseFloat(data?.misc?.value)
      }
    })
    return total?.toFixed(2)
  }

  const renderItemDetails = (quote, qIndex, isCustomization) => {
    return (
      <div>
        <div className={classes.summaryQuoteItemContainer} key={`quote-${qIndex}-price`}>
          <Typography
            variant="body1"
            className={isCustomization ? classes.summaryCustomizationPriceLabel : classes.summaryItemPriceLabel}
          >
            {quote?.price?.title}
          </Typography>
          <Typography
            variant="body1"
            className={isCustomization ? classes.summaryCustomizationPriceValue : classes.summaryItemPriceValue}
          >
            {`₹${formatIndianRupees(parseFloat(quote?.price?.value).toFixed(2))}`}
          </Typography>
        </div>
        {quote?.tax && (
          <div className={classes.summaryQuoteItemContainer} key={`quote-${qIndex}-tax`}>
            <Typography
              variant="body1"
              className={isCustomization ? classes.summaryCustomizationTaxLabel : classes.summaryItemTaxLabel}
            >
              {quote?.tax.title}
            </Typography>
            <Typography
              variant="body1"
              className={isCustomization ? classes.summaryCustomizationPriceValue : classes.summaryItemPriceValue}
            >
              {`₹${formatIndianRupees(parseFloat(quote?.tax.value).toFixed(2))}`}
            </Typography>
          </div>
        )}
        {quote?.discount && (
          <div className={classes.summaryQuoteItemContainer} key={`quote-${qIndex}-discount`}>
            <Typography
              variant="body1"
              className={isCustomization ? classes.summaryCustomizationDiscountLabel : classes.summaryItemDiscountLabel}
            >
              {quote?.discount.title}
            </Typography>
            <Typography variant="body1" className={classes.summaryItemPriceValue}>
              {`₹${formatIndianRupees(parseFloat(quote?.discount.value).toFixed(2))}`}
            </Typography>
          </div>
        )}
      </div>
    )
  }

  const getItemsTotal = (providers) => {
    let finalTotal = 0
    if (providers) {
      providers.forEach((provider) => {
        const items = Object.values(provider?.items).filter((quote) => quote?.title !== '')
        items.forEach((item) => {
          finalTotal = finalTotal + parseFloat(item?.price?.value)
          if (item?.tax) {
            finalTotal = finalTotal + parseFloat(item?.tax?.value)
          }

          if (item?.discount) {
            finalTotal = finalTotal + parseFloat(item?.discount?.value)
          }

          if (item?.customizations) {
            Object.values(item.customizations)?.forEach((custItem) => {
              finalTotal = finalTotal + parseFloat(custItem?.price?.value)
              if (custItem?.tax) {
                finalTotal = finalTotal + parseFloat(custItem?.tax?.value)
              }
            })
          }
        })
      })
    }

    return finalTotal.toFixed(2)
  }

  const renderOutofStockItems = (provider, pindex) => {
    if (productsQuote.isError && provider.errorCode === '40002' && provider.error) {
      return (
        <div key={`outof-stockpindex-${pindex}`}>
          {provider.error && provider.errorCode === '40002' ? (
            <>
              <div>
                <Typography
                  variant="body1"
                  className={`${classes.summaryItemLabel} ${classes.marginBottom10} ${classes.marginTop20} text-error`}
                >
                  Out of stock
                </Typography>
              </div>
              <div>
                <div className={`${classes.summaryQuoteItemContainer} ${classes.marginBottom10}`}>
                  <Typography variant="body1" className={classes.summaryItemQuantityLabel}>
                    Items
                  </Typography>
                  <Typography variant="body1" className={classes.summaryItemQuantityValue}>
                    Cart Quantity
                  </Typography>
                  <Typography variant="body1" className={classes.summaryItemQuantityValue}>
                    Available Quantity
                  </Typography>
                </div>
              </div>
              {provider.outOfStock.map((outOfStockItems, i) => (
                <div key={`outof-stock-item-index-${i}`}>
                  <div>
                    <div className={classes.summaryQuoteItemContainer} key={`quote-${i}-price`}>
                      <Typography variant="body1" className={classes.summaryItemQuantityLabel}>
                        {outOfStockItems?.title}
                      </Typography>
                      <Typography variant="body1" className={classes.summaryItemQuantityValue}>
                        {`${outOfStockItems?.cartQuantity}`}
                      </Typography>
                      <Typography variant="body1" className={classes.summaryItemQuantityValue}>
                        {`${outOfStockItems?.quantity}`}
                      </Typography>
                    </div>
                  </div>
                </div>
              ))}
              <Box component={'div'} className={classes.divider} />
            </>
          ) : (
            <></>
          )}
        </div>
      )
    } else {
      return <></>
    }
  }

  useEffect(() => {
    if (Object.keys(checkedState).length === deliveryModes.slice(1).length && Object.keys(checkedState).length > 0) {
      setInitOrder(true) // Ensure this is defined elsewhere in your code
    } else {
      setInitOrder(false)
    }
  }, [checkedState])

  const [deliveryObject, setDeliveryObject] = useState([])
  const handleRadioChange = (pindex, index, fulfillment) => {
    const providerId = updatedCartItems[pindex]?.message?.quote?.provider?.id
    const ProductId = updatedCartItems[pindex]?.message?.quote?.items[pindex]?.id
    const ItemObj = {
      providerId: providerId,
      ProductId: ProductId,
      fulfillment: fulfillment,
    }

    setDeliveryObject((prev) => {
      const existingIndex = prev.findIndex((item) => item.providerId === providerId)

      if (existingIndex !== -1) {
        // Override the existing fulfillment
        const newDeliveryObject = [...prev]
        newDeliveryObject[existingIndex] = ItemObj
        return newDeliveryObject
      } else {
        // Add new fulfillment
        return [...prev, ItemObj]
      }
    })

    setCheckedState((prev) => ({ ...prev, [pindex]: index }))
  }

  // const handleRadioChange = (pindex, index, fulfillment) => {
  //   // setInitOrder(true)
  //   const providerId = updatedCartItems[pindex]?.message?.quote?.provider?.id

  //   const ItemObj = {
  //     providerId: providerId,
  //     fulfillment: fulfillment,
  //   }

  //   setDeliveryObject((pre) => [...pre, ItemObj])
  //   setCheckedState({ ...checkedState, [pindex]: index })
  // }

  const renderItemsOrderSummary = (provider, pindex) => {
    return (
      <div key={`pindex-${pindex}`}>
        {Object.values(provider.items)
          .filter((quote) => quote?.title !== '')
          .map((quote, qIndex) => {
            return (
              <div key={`quote-${qIndex}`}>
                <div style={{ width: '100%' }}>
                  <ItemsWrapper key={`quote-${qIndex}-title`}>
                    {cartItems
                      .filter((item) => item?.item?.product?.id === Object.keys(provider.items)[qIndex])
                      // .filter((item) => item?.item?.product?.descriptor?.name === quote?.title)
                      .map((item, index) => (
                        <ItemImageWrapper key={index}>
                          <img
                            alt="product-image"
                            src={
                              item?.item?.product?.descriptor?.symbol
                                ? item?.item?.product?.descriptor?.symbol
                                : ProductImage
                            }
                            onError={(e) => {
                              e.target.src =
                                'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                            }}
                          />
                        </ItemImageWrapper>
                      ))}
                    <ItemsDetailWrapper>
                      <Typography variant="body1" className={`${classes.summaryItemLabel} ${quote.textClass}`}>
                        {quote?.title}
                        <p className={`${styles.ordered_from} ${quote.textClass}`}>{quote.quantityMessage}</p>
                      </Typography>
                      {renderItemDetails(quote)}
                    </ItemsDetailWrapper>
                  </ItemsWrapper>

                  {quote?.customizations && (
                    <div key={`quote-${qIndex}-customizations`}>
                      <div className={classes.summaryQuoteItemContainer} key={`quote-${qIndex}-customizations`}>
                        <Typography variant="body1" className={classes.summaryItemPriceLabel}>
                          Customizations
                        </Typography>
                      </div>
                      {Object.values(quote?.customizations).map((customization, cIndex) => (
                        <div key={`quote-${qIndex}-customizations-${cIndex}`}>
                          <div className={classes.summaryQuoteItemContainer}>
                            <Typography variant="body1" className={classes.summaryCustomizationLabel}>
                              {customization.title}
                            </Typography>
                          </div>
                          {renderItemDetails(customization, cIndex, true)}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )
          })}

        {productsQuote.isError && provider.errorCode !== '' && provider.errorCode !== '40002' && provider.error && (
          <Typography variant="body1" color="error" className={classes.summaryItemLabel}>
            {provider.error}
          </Typography>
        )}
      </div>
    )
  }

  const productDeliveryModes = deliveryModes?.slice(1) || []

  const renderItemsDeliveryMode = (provider, pindex) => {
    return (
      <div key={`pindex-${pindex}`}>
        {Object.values(provider.items)
          .filter((quote) => quote?.title !== '')
          .map((quote, qIndex) => {
            return (
              <DeliveryModeWrapper key={`quote-${qIndex}`}>
                <div style={{ width: '100%' }}>
                  <DeliveryModeItemsWrapper key={`quote-${qIndex}-title`}>
                    {cartItems
                      .filter((item) => item?.item?.product?.id === Object.keys(provider.items)[qIndex])
                      .map((item, index) => (
                        <DeliveryModeImageWrapper key={index}>
                          <img
                            alt="product-image"
                            src={
                              item?.item?.product?.descriptor?.symbol
                                ? item?.item?.product?.descriptor?.symbol
                                : ProductImage
                            }
                            onError={(e) => {
                              e.target.src =
                                'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                            }}
                          />
                        </DeliveryModeImageWrapper>
                      ))}
                    <ItemsContainer>
                      <ItemsDetailWrapper>
                        <ItemNameWrapper>{quote?.title}</ItemNameWrapper>
                      </ItemsDetailWrapper>
                      {productDeliveryModes?.length > 0 && (
                        <DeliveryMode>
                          <DeliveryModeSection>
                            <RadioGroup className="radio-group">
                              {productDeliveryModes[pindex]?.map((fulfillment, index) => {
                                // If only one delivery mode, set it as checked by default
                                if (productDeliveryModes[pindex].length === 1 && checkedState[pindex] !== 0) {
                                  handleRadioChange(pindex, 0, fulfillment) // Automatically select the first option
                                }

                                const isChecked = checkedState[pindex] === index
                                return (
                                  <RadioWrapper key={`${index}`}>
                                    <FormControlLabel
                                      className="form-controller"
                                      name={`${fulfillment['@ondc/org/category']}`}
                                      control={<Radio className="radio-button" />}
                                      checked={isChecked}
                                      onClick={() => handleRadioChange(pindex, index, fulfillment)}
                                    />
                                    <AddressDetail>{fulfillment['@ondc/org/category']}</AddressDetail>
                                  </RadioWrapper>
                                )
                              })}
                            </RadioGroup>
                          </DeliveryModeSection>
                        </DeliveryMode>
                      )}
                    </ItemsContainer>
                  </DeliveryModeItemsWrapper>
                </div>
              </DeliveryModeWrapper>
            )
          })}

        {productsQuote.isError && provider.errorCode !== '' && provider.errorCode !== '40002' && provider.error && (
          <Typography variant="body1" color="error" className={classes.summaryItemLabel}>
            {provider.error}
          </Typography>
        )}
      </div>
    )
  }

  if (cartItems === null || updatedCartItems === null) {
    return <Redirect to={'/cart'} />
  }

  return (
    <>
      <BillingContainer>
        <DetailsContainer>
          <AddressForm setUpdatedCartItems={setUpdatedCartItems} setDeliveryModes={setDeliveryModes} />
          {productDeliveryModes.length > 0 && (
            <>
              <DeliveryAddressTitle>Delivery Modes</DeliveryAddressTitle>
              <ProductDeliveryModeContainer>
                {productsQuote?.providers.map((provider, pindex) => renderOutofStockItems(provider, pindex))}
                {productsQuote?.providers.map((provider, pindex) => renderItemsDeliveryMode(provider, pindex))}
              </ProductDeliveryModeContainer>
            </>
          )}

          <StepPaymentContent
            deliveryObject={deliveryObject}
            checkedState={checkedState}
            setCheckedState={setCheckedState}
            isError={productsQuote.isError}
            // responseReceivedIds={updatedCartItems.map((item) => {
            //   const { message } = item
            //   return message?.quote?.items.map((quoteItem) => {
            //     return quoteItem.id?.toString()
            //   })
            // })}

            responseReceivedIds={updatedCartItems.flatMap((item) => {
              const { message } = item
              return message?.quote?.items.map((quoteItem) => {
                return quoteItem.id?.toString()
              })
            })}
            activePaymentMethod={activePaymentMethod}
            setActivePaymentMethod={(value) => {
              setActivePaymentMethod(value)
            }}
            cartItemsData={cartItems}
            selectedFulfillments={selectedFulfillments}
            updatedCartItemsData={updatedCartItems}
            updateInitLoading={(value) => setInitLoading(value)}
            setUpdatedCartItems={setUpdatedCartItems}
            initOrder={initOrder}
            // setInitOrder={setInitOrder}
            fulfillments={updatedCartItems.map((ele) => ele?.message?.quote?.fulfillments)}
            amount={(
              parseFloat(getItemsTotal(productsQuote?.providers)) +
              parseFloat(getDeliveryTotalAmount(productsQuote?.providers))
            ).toFixed(2)}
          />
        </DetailsContainer>

        {/* {renderQuote()} */}
        <SummaryContainer>
          <SummarCardWrapper>
            <HeadingWrapper>
              <Typography variant="h6">Order Summary</Typography>
            </HeadingWrapper>
            <Box component={'div'} className={classes.divider} />
            {/* Products */}
            <ProductContainer>
              {productsQuote?.providers.map((provider, pindex) => renderOutofStockItems(provider, pindex))}
              {productsQuote?.providers.map((provider, pindex) => renderItemsOrderSummary(provider, pindex))}
            </ProductContainer>

            {/* Product Total Amount */}
            <SubtotalContainer>
              <Typography variant="body2" className={classes.subTotalLabel}>
                Item(s) Subtotal
              </Typography>
              <Typography variant="body2" className={classes.subTotalValue}>
                {`₹${formatIndianRupees(getItemsTotal(productsQuote?.providers))}`}
              </Typography>
            </SubtotalContainer>

            {/* Items breakup details */}
            <DeliveryChargesWrapper>{renderDeliveryCharges(productsQuote?.providers)}</DeliveryChargesWrapper>

            {/* Total Order Amount */}
            <OrderTotalContainer>
              <Typography variant="body" className={classes.totalLabel}>
                Order Total
              </Typography>
              <Typography variant="body" className={classes.totalValue}>
                {`₹${formatIndianRupees(
                  (
                    parseFloat(getItemsTotal(productsQuote?.providers)) +
                    parseFloat(getDeliveryTotalAmount(productsQuote?.providers))
                  ).toFixed(2),
                )}`}
              </Typography>
            </OrderTotalContainer>
            <ProceedButtonWrapper>
              <Button
                className={classes.proceedToBuy}
                fullWidth
                variant="contained"
                disabled={activePaymentMethod === '' || productsQuote.isError || confirmOrderLoading || initLoading}
                onClick={razorPayOrder}
              >
                {confirmOrderLoading || initLoading ? <Spinner /> : 'Proceed to Pay'}
              </Button>
            </ProceedButtonWrapper>
          </SummarCardWrapper>
          {confirmOrderLoading && <Spinner />}

          {initLoading && <Spinner />}
        </SummaryContainer>
      </BillingContainer>
      <RazorpayPayment
        onApiSuccess={apiCallSuccess}
        orderId={orderId}
        amount={parseFloat(productsQuote?.total_payable).toFixed(2)}
        setRazorpayPaymentId={handlePaymentSuccess}
      />
    </>
  )
}

export default Checkout
