import React, { useContext, useState } from 'react'
import { getAuth, signInWithPopup, GoogleAuthProvider, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'
import ReactGA from 'react-ga4'
import Input from '../../components/shared/input/input'
import { toast_actions, toast_types } from '../../components/shared/toast/utils/toast'
import { AddCookie } from '../../utils/cookies'
import { ToastContext } from '../../context/toastContext'
import Google_Logo from '../../assets/images/google.png'
import OtpInput from '../../components/otpInput'
import OtpBackIcon from '../../assets/svg/OtpBackIcon'
import CloseIcon from 'assets/svg/CloseIcon'
import Logo from 'assets/images/HpLogo.png'
import { Button as NewButton } from '@mui/material'
import Button from '../../components/shared/button/button'
import { LoginContext } from 'context/loginContext'
import {
  Wrapper,
  OtpLogoContainer,
  LogoContainer,
  CrossIconWrapper,
  Title,
  FormWrapper,
  OrText,
  ButtonContainer,
  GoogleText,
  InputWrapper,
  ButtonText,
} from '../../styles/auth/Login'
import {
  LoginContainer,
  OtpLoginTextContainer,
  OtpLoginTitle,
  LoginSubTitle,
  LoginFormWrapper,
  OtpFormWrapper,
  OtpWrapper,
  OtpContainer,
  ResendText,
  ExpireOtp,
} from '../../styles/otpScreen'
import { getOrCreateDeviceId, getUserId } from 'helper'
import { getValueFromCookie } from '../../utils/cookies'
import usePost from 'hooks/usePost'
// import { CircularProgress } from '@mui/material'
import Spinner from 'components/Loader'
import Cookies from 'js-cookie'

const LoginModal = ({ onClose, onLoginSuccess, buyNow, setOrderModal }) => {
  const auth = getAuth()
  const provider = new GoogleAuthProvider()
  const { login } = useContext(LoginContext)
  const [phone, setPhone] = useState('')
  const [otp, setOtp] = useState('')
  const [loginData, setLoginData] = useState(false)
  const [inlineError, setInlineError] = useState({
    phone_error: '',
    otp_error: '',
  })
  const dispatch = useContext(ToastContext)
  const [seconds, setSeconds] = useState(120)
  const [signInUsingGoogleloading, setSignInUsingGoogleLoading] = useState(false)
  const [verificationId, setVerificationId] = useState(null)
  const [resetOtp, setResetOtp] = useState(false)
  const [loadingPhoneSubmit, setLoadingPhoneSubmit] = useState(false)
  const userId = getUserId()
  const { mutateAsync } = usePost()

  // Phone validation
  const checkPhone = () => {
    if (!phone) {
      setInlineError((inlineError) => ({
        ...inlineError,
        phone_error: 'Phone number cannot be empty',
      }))
      return false
    }

    let cleanedPhone = phone.replace(/\D/g, '')
    if (cleanedPhone.startsWith('91')) {
      cleanedPhone = cleanedPhone.slice(2)
    }

    const phoneRegex = /^[6-9]\d{9}$/
    if (!phoneRegex.test(cleanedPhone)) {
      setInlineError((inlineError) => ({
        ...inlineError,
        phone_error: 'Enter a valid 10-digit phone number',
      }))
      return false
    }

    return true
  }

  // Handle phone input change
  const handlePhoneInput = (event) => {
    const onlyNums = event.target.value.replace(/\D/g, '')

    if (onlyNums.length <= 10) {
      setPhone(onlyNums)
    }

    // Reset phone error
    setInlineError((inlineError) => ({
      ...inlineError,
      phone_error: '',
    }))
  }

  // Generate reCAPTCHA verifier
  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha',
      {
        size: 'invisible',
      },
      auth,
    )
  }

  const countDown = () => {
    if (seconds === 0) return

    const timer = setInterval(() => {
      setSeconds((prev) => {
        if (prev <= 1) {
          clearInterval(timer)
          return 0
        }

        return prev - 1
      })
    }, 1000)

    return () => clearInterval(timer)
  }

  // Firebase OTP phone login
  const handleLoginWithPhoneNumber = async () => {
    if (!checkPhone()) return
    setLoadingPhoneSubmit(true)

    const formattedPhone = `+91${phone}`
    generateRecaptcha()
    const appVerifier = window.recaptchaVerifier

    signInWithPhoneNumber(auth, formattedPhone, appVerifier)
      .then((confirmationResult) => {
        setVerificationId(confirmationResult)
        setLoginData(true)
        setLoadingPhoneSubmit(false)
        countDown()
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.success,
            message: 'OTP sent successfully!',
          },
        })
      })
      .catch(() => {
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.error,
            message: 'Error sending OTP',
          },
        })
      })
  }

  // Check OTP and validate it with Firebase
  const handleOtpLogin = async () => {
    if (otp.length !== 6) {
      setInlineError((inlineError) => ({
        ...inlineError,
        otp_error: 'Please enter a 6-digit OTP',
      }))
      return
    }

    try {
      const result = await verificationId.confirm(otp)
      const user = result.user
      const token = user.accessToken
      const formattedPhone = `+91${phone}`
      const { uid } = user
      // Add userId, phone, and token to cookies
      AddCookie('userId', uid)
      AddCookie('token', token)
      AddCookie('phone', JSON.stringify({ phone: formattedPhone }))
      handleRedirect(user) // Handle redirection post login
      handleNew('phone')
    } catch (error) {
      setInlineError((inlineError) => ({
        ...inlineError,
        otp_error: 'Invalid OTP, please try again',
      }))
    }
  }

  const handleNew = async (type) => {
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: 'Login with mobile number',
    })

    let user_Id
    let userEmail
    const userCookie = getValueFromCookie('user')
    if (userCookie) {
      const user = JSON.parse(userCookie)
      user_Id = user.id
      userEmail = user.email
    }

    // const customCartId = localStorage.getItem('custom_cart_id')
    // if (!customCartId) {
    //   const randomID = await generateUniqueBrowserId()
    //   localStorage.setItem('custom_cart_id', randomID)
    // }

    const deviceId = await getOrCreateDeviceId()

    const payload = {
      userId: user_Id || userId || '',
      deviceId: deviceId || '',
      signup_with: type,
      ...(type === 'phone' && { phone: `+91${phone}` || '' }),
      ...(type === 'email' && { email: userEmail || '' }),
    }

    try {
      const res = await mutateAsync({
        url: '/clientApis/userProfile',
        payload: payload,
      })

      const userCookie = Cookies.get('user')
      if (userCookie) {
        const user = JSON.parse(userCookie)

        user.phone = res?.data?.phone

        const updatedUserCookie = JSON.stringify(user)

        Cookies.set('user', updatedUserCookie)
      }

      login()
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err?.response?.data?.error?.message,
        },
      })
    }
  }

  // Google login
  const handleLoginWithGoogle = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: 'Login with google',
    })
    setSignInUsingGoogleLoading(true)

    signInWithPopup(auth, provider)
      .then((result) => {
        handleRedirect(result.user)
        handleNew('email')
      })
      .catch((error) => {
        if (error.code !== 'auth/popup-closed-by-user') {
          dispatch({
            type: toast_actions.ADD_TOAST,
            payload: {
              id: Math.floor(Math.random() * 100),
              type: toast_types.error,
              message: error.message,
            },
          })
        }
      })
      .finally(() => setSignInUsingGoogleLoading(false))
  }

  // Handle successful login
  const handleRedirect = (user) => {
    const { displayName, email, accessToken, uid, refreshToken, phoneNumber } = user
    AddCookie('token', accessToken)
    AddCookie('refreshToken', refreshToken)

    AddCookie('user', JSON.stringify({ name: displayName, id: uid, email, phone: phoneNumber }))

    dispatch({
      type: toast_actions.ADD_TOAST,
      payload: {
        id: Math.floor(Math.random() * 100),
        type: toast_types.success,
        message: 'Logged In Successfully!',
      },
    })

    login()
    onClose()

    if (!buyNow) {
      setTimeout(() => {
        onLoginSuccess()
      }, 1000)
    } else {
      setOrderModal(true)
    }
  }

  const getFullOtp = (otp) => {
    if (otp?.length === 6) setOtp(otp)
  }

  return (
    <Wrapper isOtpPage={loginData}>
      {loginData ? (
        <OtpLogoContainer>
          <div
            onClick={() => {
              if (seconds !== 0) return // Prevent action if loading
              setLoginData(false)
              setSeconds(60)
            }}
          >
            <OtpBackIcon className={seconds !== 0 ? 'disabled' : ''} />
          </div>

          <img src={Logo} alt="Himira" />
          <CrossIconWrapper>
            <CloseIcon onClick={onClose} />
          </CrossIconWrapper>
        </OtpLogoContainer>
      ) : (
        <LogoContainer>
          <img src={Logo} alt="Himira" />
          <CrossIconWrapper>
            <CloseIcon onClick={onClose} />
          </CrossIconWrapper>
        </LogoContainer>
      )}

      {!loginData ? (
        <FormWrapper>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              handleLoginWithPhoneNumber()
            }}
          >
            <Title>Sign In and Let&apos;s Explore!</Title>
            <InputWrapper>
              <Input
                id="phone"
                name="phone"
                type="number" // Change to text for easier input handling
                placeholder="Enter Your Phone Number"
                label_name="Phone Number"
                autoComplete="off"
                value={phone}
                onChange={handlePhoneInput}
                has_error={inlineError.phone_error}
                required
              />
              {inlineError.phone_error && <span>{inlineError.phone_error}</span>}
            </InputWrapper>
            <ButtonContainer>
              <NewButton
                className="login_button"
                variant="outlined"
                onClick={handleLoginWithPhoneNumber}
                disabled={loadingPhoneSubmit} // Disable button while loading
              >
                Next
              </NewButton>
              {loadingPhoneSubmit && <Spinner />} {/* Show spinner next to the button */}
              <OrText>Or</OrText>
              <Button
                className="google_button"
                isloading={signInUsingGoogleloading ? 1 : 0}
                disabled={signInUsingGoogleloading}
                button_text={
                  <GoogleText style={{ display: 'flex', gap: '10px' }}>
                    <img src={Google_Logo} alt="logo" />
                    <ButtonText>Login with google</ButtonText>
                  </GoogleText>
                }
                onClick={handleLoginWithGoogle}
              />
            </ButtonContainer>
            <div id="recaptcha"></div>
          </form>
        </FormWrapper>
      ) : (
        <FormWrapper>
          <LoginContainer>
            <OtpLoginTextContainer>
              <OtpLoginTitle>Enter OTP</OtpLoginTitle>
              <LoginSubTitle>Please enter the OTP sent to your phone.</LoginSubTitle>
            </OtpLoginTextContainer>
            <LoginFormWrapper>
              <OtpFormWrapper onSubmit={handleOtpLogin}>
                <OtpContainer>
                  <OtpWrapper>
                    <OtpInput length={6} getFullOtp={getFullOtp} resetOtp={resetOtp} setResetOtp={setResetOtp} />
                  </OtpWrapper>
                  {inlineError.otp_error && <span>{inlineError.otp_error}</span>}
                </OtpContainer>
                {seconds <= 0 ? '' : <ExpireOtp>OTP Expires in {seconds}s</ExpireOtp>}
                <NewButton variant="contained" onClick={handleOtpLogin}>
                  Verify
                </NewButton>
              </OtpFormWrapper>
              <ResendText
                onClick={seconds === 0 ? handleLoginWithPhoneNumber : null}
                disabled={seconds > 0}
                className={seconds > 0 ? 'disable-button' : ''}
              >
                Resend OTP
              </ResendText>
            </LoginFormWrapper>
          </LoginContainer>
          <div id="recaptcha"></div>
        </FormWrapper>
      )}
    </Wrapper>
  )
}

export default LoginModal
