import ReactGA from 'react-ga4'
import { useHistory } from 'react-router-dom'
import Button from '@mui/material/Button'
import IssueImage from 'assets/images/HpImages/order-image.png'
import {
  Container,
  Wrapper,
  MainWrapper,
  OrderDetailSection,
  ImageWrapper,
  OrderDetails,
  OrderIdWrapper,
  OrderId,
  IssueCountWrapper,
  CountTitle,
  IssueCount,
  ButtonWrapper,
} from 'styles/ComplaintsCard'

const ComplaintsCard = (issueDetails) => {
  const issuesOrderId = issueDetails?.issueDetails?.orderId
  const history = useHistory()

  return (
    <Container>
      <Wrapper>
        <MainWrapper>
          <OrderDetailSection>
            <ImageWrapper>
              <img src={IssueImage} />
            </ImageWrapper>
            <OrderDetails>
              <OrderIdWrapper>
                <OrderId>Order ID : </OrderId>
                <OrderId>{issuesOrderId}</OrderId>
              </OrderIdWrapper>
              <IssueCountWrapper>
                <CountTitle>No. of Issues Raised </CountTitle>
                <IssueCount>{issueDetails?.issueDetails?.no_of_issues}</IssueCount>
              </IssueCountWrapper>
            </OrderDetails>
          </OrderDetailSection>
          <ButtonWrapper>
            <Button
              variant="outlined"
              onClick={() => {
                history.push(`/profile/complaints/${issuesOrderId}`)

                ReactGA.event({
                  category: 'Sidebar',
                  action: 'Click',
                  label: 'View Details',
                })
              }}
            >
              View Details
            </Button>
          </ButtonWrapper>
        </MainWrapper>
      </Wrapper>
    </Container>
  )
}

export default ComplaintsCard
