import { makeStyles } from '@mui/styles'
import { styled } from 'styled-components'
import palette from '../../../utils/Theme/palette'

const useStyles = makeStyles({
  subCatContainerMain: {
    padding: '32px 54px 16px 54px',
    // marginTop: '32px !important',
    overflow: 'hidden',
  },
  subCatContainer: {
    display: 'flex',
    // gap: '25px',
    // overflow: 'auto',
    padding: '20px 50px',
    '&::-webkit-scrollbar': {
      // display: 'none',
      height: '8px',
      scrollbarColor: `${palette.primary.main} !important`,
    },
    '&::-webkit-scrollbar-thumb': {
      background: `${palette.primary.main} !important`,
    },
  },
  paginationActionContainer: {
    display: 'flex',
    gap: '25px',
  },
  subCatCard: {
    cursor: 'pointer',
    height: '120px !important',
    width: '120px !important',
    minWidth: '120px !important',
    borderRadius: '8px !important',
    background: '#fff !important',
    display: 'flex',
    boxShadow: 'none !important',
    border: '1px solid #E4E4E4',
  },
  subCatImage: {
    margin: 'auto',
    height: '140px !important',
    width: '140px !important',
    padding: '5px',
  },
  subCatNameTypo: {
    fontWeight: '550 !important',
    marginTop: '13px !important',
    textAlign: 'center',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  dotsContainer: {
    textAlign: 'center',
  },
  dot: {
    height: '10px',
    width: '10px',
    backgroundColor: '#D9D9D9',
    borderRadius: '50%',
    display: 'inline-block',
    marginLeft: '5px',
  },
  selectedDot: {
    height: '10px',
    width: '20px',
    backgroundColor: palette.primary.main,
    borderRadius: '10px',
    display: 'inline-block',
    marginLeft: '5px',
  },
  categoriesContainer: {
    '& ul': {
      overflow: 'auto',
      display: 'flex',
      gap: '10px',
      // padding: '16px !important',
      justifyContent: 'center',
      '& li:has(.MuiPaginationItem-ellipsis)': {
        background: 'red !important',
        display: 'none',
      },
    },
    textAlign: 'center',
    margin: 'auto',
  },
  isActive: {
    border: `2px solid ${palette.primary.main}`,
  },
  '@media (max-width: 600px)': {
    subCatContainerMain: {
      padding: '16px 25px',
    },
    subCatContainer: {
      padding: '20px 0',
    },
  },
})

export default useStyles

export const CategoriesContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
  img {
    cursor: pointer;
  }

  @media screen and (max-width: 1068px) {
    display: grid;
    grid-template-columns: auto auto auto;
  }
  @media screen and (max-width: 468px) {
    display: grid;
    grid-template-columns: auto auto;
    margin-top: 0px;
  }
`
export const ProductsCategoriesContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 120px 15px 15px 15px;

  img {
    cursor: pointer;
  }

  @media screen and (max-width: 1068px) {
    display: grid;
    grid-template-columns: auto auto auto;
  }
  @media screen and (max-width: 468px) {
    display: grid;
    grid-template-columns: auto auto;
    margin-top: 86px;
  }
`
export const CategoryContainer = styled.div`
  color: ${({ theme }) => theme.secondaryColor};
  margin: 120px 15px 15px 15px;

  @media (max-width: 767px) {
    .MuiGrid-item {
      padding-top: 19px;
    }
    .products-wrapper {
      padding-top: 0 !important;
    }
  }
`
export const ProductCategoryContainer = styled.div`
  color: ${({ theme }) => theme.secondaryColor};
  margin: 15px 15px 15px 15px;

  @media (max-width: 767px) {
    margin: 0;

    .MuiGrid-item {
      padding-top: 19px;
    }
    .products-wrapper {
      padding-top: 0 !important;
    }
  }
`
export const CategoryWrapper = styled.div`
  color: ${({ theme }) => theme.secondaryColor};
  margin: 15px;

  @media (max-width: 767px) {
    .MuiGrid-item {
      padding-top: 19px;
    }
    .products-wrapper {
      padding-top: 0 !important;
    }
  }
`
// export const IconButton = styled.div`
//   padding: 10px;
//   border-radius: 50%;
//   background-color: ${palette.secondary.main};
//   cursor: pointer;
//   width: max-content;
// `
export const IconButton = styled.div`
  padding: 10px;
  border-radius: 50%;
  background-color: ${palette.secondary.main};
  cursor: pointer;
  width: max-content;
  @media (max-width: 450px) {
    display: none;
  }
`
export const SubCategoryWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 30px;
  min-height: 200px;
  max-width: 92%;
  @media screen and (max-width: 468px) {
    margin: 0 10px;
  }
`
