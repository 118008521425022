import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useHistory } from 'react-router-dom'
import Banner1 from 'assets/images/HpImages/Banner11.png'
import Banner2 from 'assets/images/HpImages/Banner10.png'
import BannerMobile1 from 'assets/images/HpImages/MobileBanner1.png'
import BannerMobile2 from 'assets/images/HpImages/MobileBanner2.png'
import { BannerWrapper, BannerItem } from 'styles/hpLayout/bannerSection'

const banners = [
  {
    src: Banner1,
    mobile: BannerMobile1,
    alt: 'Banner 1',
    title: "Nature's Goodness, Handmade with Love.",
    subtext: 'Pure | Natural | Homemade',
  },
  {
    src: Banner2,
    mobile: BannerMobile2,
    alt: 'Banner 2',
    title: 'A Taste of Tradition Delivered to Your Door',
    subtext: 'Savor the flavors of rural India.',
  },
]

const BannerSlider = ({ loginModal }) => {
  const sliderRef = useRef(null)
  const [autoplay, setAutoplay] = useState(true)

  useEffect(() => {
    if (sliderRef.current) {
      if (loginModal) {
        setAutoplay(false)
        sliderRef.current.slickPause()
      } else {
        setAutoplay(true)
        sliderRef.current.slickPlay()
      }
    }
  }, [loginModal])

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: autoplay,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const history = useHistory()

  const handleClick = () => {
    history.push('/categories')
  }

  return (
    <BannerWrapper>
      <Slider ref={sliderRef} {...settings}>
        {banners.map((banner, index) => (
          <BannerItem key={index} onClick={handleClick}>
            <img src={banner.src} alt={banner.alt} />
          </BannerItem>
        ))}
      </Slider>
    </BannerWrapper>
  )
}

export default BannerSlider
