import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useGet from 'hooks/useGet'
import { getOrCreateDeviceId, getUserId } from 'helper'
import CustomButton from 'components/customButton'
import ForwardIcon from 'assets/svg/HPSvgs/ForwardIcon'
import ProductImage from 'assets/images/no_image_found.png'
import {
  Container,
  MobileMainHeading,
  ProductImageSection,
  ProductMainImage,
  ProductIngredientsSection,
  DescriptionSection,
  ContentSection,
  MainHeading,
  ProccessDescription,
  ButtonWrapper,
} from 'styles/hpLayout/productProcessingSection'

const ProductProcessingSection = () => {
  const history = useHistory()

  const [deviceId, setDeviceId] = useState()
  const execute = async () => {
    const deviceId = await getOrCreateDeviceId()
    setDeviceId(deviceId)
  }

  const userId = getUserId()
  const reqParams = {
    userId: userId,
    deviceId: deviceId,
  }
  const { data: latestProductData, refetch: fetchLatestProducts } = useGet(
    'latest_products',
    `/clientApis/v2/latest_products`,
    reqParams,
    { enabled: !!deviceId },
  )
  const latestProduct = latestProductData?.data[0]
  useEffect(() => {
    execute()
    if (deviceId) fetchLatestProducts()
  }, [deviceId, fetchLatestProducts])

  return (
    <Container>
      <MobileMainHeading>How we make it</MobileMainHeading>
      <ProductImageSection>
        <ProductMainImage>
          <img
            src={
              latestProduct?.item_details?.descriptor?.symbol
                ? latestProduct?.item_details?.descriptor?.symbol
                : ProductImage
            }
            alt="product-name"
            onError={(e) => {
              e.target.src =
                'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
            }}
          />
        </ProductMainImage>
        <ProductIngredientsSection>
          <ProductMainImage>
            <img
              src={
                latestProduct?.item_details?.descriptor?.images[0]
                  ? latestProduct?.item_details?.descriptor?.images[0]
                  : ProductImage
              }
              alt="ingrideint-name"
              onError={(e) => {
                e.target.src =
                  'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
              }}
            />
          </ProductMainImage>
        </ProductIngredientsSection>
      </ProductImageSection>
      <DescriptionSection>
        <ContentSection>
          <MainHeading>How we make it</MainHeading>
          <ProccessDescription>
            At HimIra, we carefully source the freshest, all-natural spices directly from the Himalayas. Our process
            ensures that every spice retains its rich flavor and aroma, from handpicking to careful packaging. With a
            commitment to purity and quality, we deliver authentic Himalayan spices to your kitchen, bringing the taste
            of nature straight to your home in every order.
          </ProccessDescription>
        </ContentSection>
        <ButtonWrapper>
          <CustomButton
            label="Shop Now"
            variant="contained1"
            icon={<ForwardIcon />}
            onClick={() => {
              history.push(`products?productId=${latestProduct?.id}`)
            }}
          />
        </ButtonWrapper>
      </DescriptionSection>
    </Container>
  )
}

export default ProductProcessingSection
