import React, { useState, useContext, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { LocationContext } from 'context/locationContext'
import { ToastContext } from 'context/toastContext'
import { toast_actions, toast_types } from 'components/shared/toast/utils/toast'
import { PinCodeContainer } from 'styles/navbar'
import { PincodeButtonWrapper } from 'styles/hpLayout'
import { getCall } from 'api/axios'


const Pincode = ({ onClose, currentPincode }) => {
  const { setLocation } = useContext(LocationContext)
  const [pinCode, setPinCode] = useState()
  const dispatch = useContext(ToastContext)
  const [validatePinCode, setValidatePinCode] = useState(false)
  const [error, setError] = useState({
    areaCode_error: '',
  })

  const getPinCode = localStorage.getItem('pinCode')

  const checkPinCode = () => {
    setError((error) => ({
      ...error,
      areaCode_error: '',
    }))

    let pin = pinCode !== undefined ? pinCode : getPinCode ? getPinCode : currentPincode

    if (!pin || pin?.length !== 6 || !/^\d+$/.test(pin)) {
      setError((error) => ({
        ...error,
        areaCode_error: 'Pin code must contain only 6 digits',
      }))
      return false
    }

    return true
  }

  const getPinCodeAddressRequest = async () => {
    try {
      const data = await getCall(
        `/clientApis/v2/map/getCordinates?postcode=${pinCode ? pinCode : getPinCode ? getPinCode : currentPincode}`,
      )
      setLocation(data?.data?.latitude, data?.data?.longitude)
      localStorage.setItem('pinCode', pinCode ? pinCode : getPinCode ? getPinCode : currentPincode)
      localStorage.setItem('latAndLng', data?.data?.latitude.toFixed(6), data?.data?.longitude.toFixed(6))
      if (data) {
        onClose()
      }

      return data
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: 'Invalid Pin Code',
        },
      })
      onClose()
    }
  }

 

  const handleSubmit = () => {
    setValidatePinCode(true)
    if (checkPinCode()) {
      getPinCodeAddressRequest()
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [pinCode])

  return (
    <Grid container spacing={3}>
      <PinCodeContainer>
        <TextField
          required
          fullWidth
          id="pin_code-input"
          name="pin_code"
          placeholder="_ _ _ _ _ _"
          type="text"
          className="pincode"
          value={pinCode !== undefined ? pinCode : getPinCode ? getPinCode : currentPincode}
          inputProps={{
            maxLength: 6,
          }}
          onChange={(event) => {
            const regexp = /^[0-9]+$/
            if (!regexp.test(event.target.value) && event.target.value !== '') return
            const areaCode = event.target.value
            setPinCode(areaCode)
            setError((error) => ({
              ...error,
              areaCode_error: '',
            }))
          }}
          error={!!(validatePinCode && error.areaCode_error)}
          helperText={(validatePinCode && error.areaCode_error) || ''}
          onBlur={() => {
            if (validatePinCode) {
              checkPinCode()
            }
          }}
        />
        <PincodeButtonWrapper>
          <Button variant="outlined" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </PincodeButtonWrapper>
      </PinCodeContainer>
    </Grid>
  )
}

export default Pincode
