import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const MainContainer = styled.div`
  display: flex;
  gap: 50px;
  justify-content: center;
  margin: 108px 100px 0 100px;
  @media (max-width: 767px) {
    margin: 75px 16px;
    justify-content: start;
  }
`
export const Container = styled.div`
  flex: 1;
  width: 100%;
`
export const Wrapper = styled.div`
  display: flex;
  width: 85vw;
  gap: 50px;
  @media (max-width: 767px) {
    width: 100%;
  }
`
export const ComponentWrapper = styled.div`
  width: 100%;
`

export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 215px;
  width: 100%;
  gap: 12px;
  @media (max-width: 768px) {
    display: none;
  }
`

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
export const UserLabel = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${theme.PRIMARYTEXTCOLOR};
`
export const WelcomeMessage = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.SECONDARYGREYCOLOR};
`

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const ListItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0px 16px 16px;
  gap: 15px;
  background: ${({ isActive }) => (isActive ? `${theme.TERTIARYBACKGROUNDCOLOR}` : '')};
  cursor: pointer;
`
export const SidebarTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${theme.PRIMARYTEXTCOLOR};
`
export const SidebarIcon = styled.div``
