import React, { useState, useContext, useEffect } from 'react'
import {
  AddressModalWrapper,
  AddAddressWrapper,
  AddressWrapper,
  AddressButtonWrapper,
  NameWrapper,
  Title,
  InputFieldWrapper,
  ErrorMessage,
} from 'styles/orderModal'
import { Controller, useForm } from 'react-hook-form'
import { TextField } from '@mui/material'
import usePost from 'hooks/usePost'
import { AddCookie } from 'utils/cookies'
import axios from 'axios'
import { ToastContext } from 'context/toastContext'
import { toast_actions, toast_types } from 'components/shared/toast/utils/toast'
import useGet from 'hooks/useGet'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { yupResolver } from '@hookform/resolvers/yup'
import { EDIT_ADDRESS_VALIDATION } from 'utils/Validation/addressForm-validation'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Spinner from 'components/Loader'
import CustomButton from 'components/customButton'

const EditAddressModal = ({ onClose, addressDetails }) => {
  const { id, address, user, descriptor } = addressDetails
  const [locality, setLocality] = useState([{}])
  const [pincodeCoords, setPincodeCoords] = useState([])
  const [postOffice, setPostOffice] = useState([{}])
  const [loader, setLoader] = useState(false)
  const dispatch = useContext(ToastContext)

  const {
    control,
    handleSubmit,
    // TODO: Need to add reset function
    reset,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(EDIT_ADDRESS_VALIDATION),
  })

  const { mutateAsync } = usePost()
  const { refetch: fetchDeliveryAddress } = useGet('delivery_address', `/clientApis/v1/delivery_address`)

  const { pincode, city } = watch()

  const getAddressDetails = async () => {
    try {
      setLoader(true)

      const response = await axios.get(`https://api.postalpincode.in/pincode/${pincode}`)

      const { data: pincodeCoordinatesData, status } = await axios.get(
        `https://nominatim.openstreetmap.org/search?q=${pincode}&format=json&limit=1&countrycodes=IN`,
      )

      if (status === 200) {
        setPincodeCoords([pincodeCoordinatesData[0]?.lat, pincodeCoordinatesData[0]?.lon])
      }

      if (response.data[0]?.Status === 'Error') {
        setLoader(false)

        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.error,
            message: 'Invalid Pincode',
          },
        })
        setValue('locality', '')
        setValue('state', '')
        setValue('city', '')
        setValue('country', '')
      } else if (response.data && response.data[0] && response.data[0].PostOffice) {
        const postOffices = response.data[0].PostOffice
        setPostOffice(postOffices)
        const nameList = postOffices.map((postOffice) => postOffice.Name)
        setValue('locality', nameList[0])
        setLocality(nameList)
        setLoader(false)
      }
    } catch (err) {
      return err
    }
  }

  const handleLocalityChange = (selectedLocality) => {
    const selectedPostOffice = postOffice.find((postOffice) => postOffice.Name === selectedLocality)

    if (selectedPostOffice) {
      const { State, Block, District, Name, Country } = selectedPostOffice

      setValue('state', State)
      setValue('city', Block === 'NA' ? District : Block)
      setValue('locality', Name)
      setValue('country', Country)
    }
  }

  useEffect(() => {
    if (pincode?.length === 6) {
      getAddressDetails()
    }
  }, [pincode])

  const createLocalityObjects = (locality) => {
    const localities = []
    for (let i = 0; i < locality.length; i++) {
      const localityObject = {
        value: locality[i],
      }
      localities.push(localityObject)
    }

    return localities
  }

  const phoneNumberWithCountryCode = `+91${descriptor?.phone}`

  useEffect(() => {
    setValue('name', address?.name)
    setValue('phone', phoneNumberWithCountryCode)
    setValue('email', descriptor?.email)
    setValue('locality', address?.locality)
    setValue('city', address?.city)
    setValue('building', address?.building)
    setValue('state', address?.state)
    setValue('country', address?.country)
    setValue('pincode', address?.areaCode)
  }, [])

  const onSubmit = async (address) => {
    const latitude = parseFloat(pincodeCoords[0]).toFixed(6).trim()
    const longitude = parseFloat(pincodeCoords[1]).toFixed(6).trim()
    const gps = `${latitude},${longitude}`
    try {
      let phoneWithoutCountryCode = address.phone
      if (phoneWithoutCountryCode.startsWith('91')) {
        phoneWithoutCountryCode = phoneWithoutCountryCode.slice(2)
      } else if (phoneWithoutCountryCode.startsWith('+91')) {
        phoneWithoutCountryCode = phoneWithoutCountryCode.slice(3)
      }

      const payload = {
        descriptor: {
          name: user?.name ? user?.name : address?.name,
          // email: user.email ? user.email : address.email,
          email: address.email,
          images: [user?.photoURL],
          phone: phoneWithoutCountryCode,
        },
        address: {
          name: address.name,
          email: address.email,
          areaCode: address.pincode,
          city: address.city,
          country: address.country,
          state: address.state,
          building: address.building.trim(),
          locality: address.locality.trim(),
          street: address.locality.trim(),
          tag: 'Home', // TODO
          lat: parseFloat(pincodeCoords[0]).toFixed(6),
          lng: parseFloat(pincodeCoords[1]).toFixed(6),
        },
        gps: gps,
      }

      const data = await mutateAsync({
        url: `/clientApis/v1/update_delivery_address/${id}`,
        payload: payload,
      })

      if (data) {
        reset()
        onClose()
        fetchDeliveryAddress()
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.success,
            message: 'Address updated.',
          },
        })
      }

      AddCookie('delivery_address', JSON.stringify(data))
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err?.response?.data?.error?.message,
        },
      })
    }
  }

  return (
    <AddressModalWrapper>
      {loader && <Spinner />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <AddAddressWrapper>
          <AddressWrapper>
            <NameWrapper>
              <Title>
                Name<span className="asterik">*</span>
              </Title>
              <InputFieldWrapper>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      type="text"
                      id="name"
                      placeholder="Enter Name"
                      {...field}
                      style={{ width: '100%' }}
                      onChange={(value) => {
                        field.onChange(value)
                      }}
                    />
                  )}
                />
                <ErrorMessage>{errors?.name?.message}</ErrorMessage>
              </InputFieldWrapper>
            </NameWrapper>
            <NameWrapper>
              <Title>
                Email<span className="asterik">*</span>
              </Title>
              <InputFieldWrapper>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      type="text"
                      id="name"
                      placeholder="Enter Email"
                      style={{ width: '100%' }}
                      {...field}
                      onChange={(value) => {
                        field.onChange(value)
                      }}
                    />
                  )}
                />
                <ErrorMessage>{errors?.email?.message}</ErrorMessage>
              </InputFieldWrapper>
            </NameWrapper>
            <NameWrapper>
              <Title>
                Phone<span className="asterik">*</span>
              </Title>
              <InputFieldWrapper>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      countryCodeEditable={false}
                      country="in"
                      value={value}
                      onChange={onChange}
                      className="phone-input"
                      disableDropdown
                      placeholder="Enter Phone Number"
                      inputStyle={{ boxShadow: 'none' }}
                      inputProps={{
                        name: 'phone',
                        autoFocus: false,
                        focus: false,
                      }}
                    />
                  )}
                />
                <ErrorMessage>{errors?.phone?.message}</ErrorMessage>
              </InputFieldWrapper>
            </NameWrapper>
            <NameWrapper>
              <Title>
                Building<span className="asterik">*</span>
              </Title>
              <InputFieldWrapper>
                <Controller
                  name="building"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      type="text"
                      id="building"
                      placeholder="Enter Building"
                      {...field}
                      onChange={(value) => {
                        field.onChange(value)
                      }}
                    />
                  )}
                />
                <ErrorMessage>{errors?.building?.message}</ErrorMessage>
              </InputFieldWrapper>
            </NameWrapper>
            <NameWrapper>
              <Title>
                Pincode<span className="asterik">*</span>
              </Title>
              <InputFieldWrapper>
                <Controller
                  name="pincode"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => <TextField type="text" id="pincode" {...field} placeholder="Enter Pincode" />}
                />
                <ErrorMessage>{errors?.pincode?.message}</ErrorMessage>
              </InputFieldWrapper>
            </NameWrapper>
            <NameWrapper>
              <Title>
                Locality<span className="asterik">*</span>
              </Title>
              <InputFieldWrapper>
                <Controller
                  name="locality"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      type="text"
                      id="locality"
                      placeholder="Enter Locality"
                      {...field}
                      className="locality-wrapper"
                      disabled={city ? false : true}
                      onChange={(e) => handleLocalityChange(e.target.value)}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            height: '200px',
                            overflowY: 'scroll',
                          },
                        },
                      }}
                    >
                      {createLocalityObjects(locality).map((localityObj, index) => (
                        <MenuItem key={index} value={localityObj.value}>
                          {localityObj.value}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <ErrorMessage>{errors?.locality?.message}</ErrorMessage>
              </InputFieldWrapper>
            </NameWrapper>

            <NameWrapper>
              <Title>City</Title>
              <InputFieldWrapper>
                <Controller
                  name="city"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField type="text" id="city" {...field} placeholder="Enter City" disabled />
                  )}
                />
                <ErrorMessage>{errors?.city?.message}</ErrorMessage>
              </InputFieldWrapper>
            </NameWrapper>
            <NameWrapper>
              <Title>
                Region/State<span className="asterik">*</span>
              </Title>
              <InputFieldWrapper>
                <Controller
                  name="state"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField type="text" id="state" {...field} placeholder="Enter Region/State" disabled />
                  )}
                />
                <ErrorMessage>{errors?.state?.message}</ErrorMessage>
              </InputFieldWrapper>
            </NameWrapper>
            <NameWrapper>
              <Title>
                Country<span className="asterik">*</span>
              </Title>
              <InputFieldWrapper>
                <Controller
                  name="country"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField type="text" id="country" {...field} placeholder="Enter Country" disabled />
                  )}
                />
                <ErrorMessage>{errors?.country?.message}</ErrorMessage>
              </InputFieldWrapper>
            </NameWrapper>
          </AddressWrapper>
          <AddressButtonWrapper>
            {/* <Button variant="contained" type="submit">
              Update Address
            </Button> */}
            <CustomButton variant="contained1" type="submit" label="Update Address" />
          </AddressButtonWrapper>
        </AddAddressWrapper>
      </form>
    </AddressModalWrapper>
  )
}

export default EditAddressModal
