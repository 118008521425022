import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const ForgotPasswordWrapper = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 1068px) {
    justify-content: center;
  }
  @media (max-width: 767px) {
    min-height: calc(100vh - 0px);
  }
`
export const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  /* gap: 32px; */
  /* padding: 20px; */
  @media (max-width: 767px) {
    width: 100%;
    align-items: unset;
    gap: 100px;
  }
`
export const OtpRightSection = styled(RightSection)`
  gap: 195px;
  /* width: 60%; */
  @media (max-width: 1300px) {
    gap: 132px;
  }
  @media (max-width: 767px) {
    width: 100%;
    gap: 110px;
  }
`
export const MobileWrapper = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
`
export const CancelWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const WebWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 767px) {
    display: none;
  }
`

export const BackArrowWrapper = styled.div`
  svg {
    cursor: pointer;
  }
`

// reset password css
export const ResetPasswordSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  gap: 150px;
  padding: 20px;
  @media (max-width: 1500px) {
    gap: 45px;
  }
  @media (max-width: 767px) {
    width: 100%;
    gap: 18px;
  }
`
export const PasswordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 60px;
`
export const PasswordValidationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  path {
    width: 15px;
    height: 15px;
  }

  rect {
    width: 15px;
    height: 15px;
  }
  @media (max-width: 767px) {
    margin-top: 4px;
    row-gap: 8px;
  }
`

export const LoginWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  @media (max-width: 1068px) {
    justify-content: center;
  }
  @media (max-width: 767px) {
    overflow-y: auto;
  }
`
export const LeftSection = styled.div`
  display: flex;
  width: 40%;
  @media (max-width: 1068px) {
    display: none;
  }
`

export const LoginRightSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  gap: 180px;
  padding: 14px;
  @media (max-width: 1700px) {
    gap: 119px;
  }
  @media (max-width: 1500px) {
    gap: 134px;
  }
  @media (max-width: 1300px) {
    gap: 65px;
  }
  @media (max-width: 767px) {
    width: 100%;
    gap: 140px;
  }
`

export const OTPOptionWrapper = styled.div`
  display: flex;
  gap: 10px;
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 0;
  }
`

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  gap: 20px;
  /* margin: 0 auto; */
  @media (max-width: 767px) {
    gap: 16px;
    justify-content: center;
    align-items: center;
  }
`
export const LogoWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  img {
    max-width: 126px;
    width: 100%;
  }
  @media (max-width: 767px) {
    display: none;
  }
`
export const LoginTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    justify-content: center;
    align-items: center;
    gap: 0;
  }
`
export const OtpLoginTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    gap: 0;
    width: 100%;
  }
`
export const LoginTitle = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #1a1a1a;
  @media (max-width: 767px) {
    text-align: center;
    font-size: 25px;
  }
`
export const OtpLoginTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  /* line-height: 40px; */
  color: ${theme.PRIMARYTEXTCOLORDARK};
  @media (max-width: 767px) {
    font-size: 25px;
  }
  .asterik {
    color: ${theme.SECONDARYERRORCOLOR};
  }
`
export const LoginSubTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.GREYCOLOR};

  @media (max-width: 450px) {
    padding: 0;
  }
`
export const LoginFormWrapper = styled.div`
  width: 100%;
  max-width: 438px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 17px;
`
export const OtpFormWrapper = styled.form`
  width: 100%;
  max-width: 360px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;

  .buttonSection {
    justify-content: center;
    display: flex;

    @media (max-width: 450px) {
      margin: 3px;
    }
  }

  button {
    /* width: 160px; */
    padding: 8px 16px;
    background: ${theme.PRIMARYCOLOR} !important;
    color: ${theme.SECONDARYTEXTCOLOR};
    text-align: center;
    border-radius: 10px;
    text-transform: capitalize;
    width: 182px;
    font-weight: 500;
    margin-top: 6px;
    font-family: 'montserrat' !important;
  }
`
export const FormWrapper = styled.form`
  width: 100%;
  max-width: 438px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  @media (max-width: 767px) {
    gap: 2px;
  }
`
export const ExpireOtp = styled.p`
  color: #979797;
  font-size: 12px;
`
export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  svg {
    height: 18px;
    position: absolute;
    right: 10px;
    top: 38px;
  }
`

export const ForgotText = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: #ec6d51;
  span {
    cursor: pointer;
  }
`

export const OtpWrapper = styled.div`
  display: flex;
  grid-template-columns: repeat(5, 1fr);
  gap: 7px;
  width: 100%;
  max-width: 360px;
  padding: 10px 0;
  input {
    width: 37px;
    /* max-width: 43px; */
    height: 58px;
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    height: 37px;
    border: 1.5px solid ${theme.GREYCOLORDARK};
    border-radius: ${({ error }) => (error ? '8px 8px 2px 2px' : '8px')};
    border-radius: ${({ error }) => (error ? '8px 8px 2px 2px' : '8px')};
    box-shadow: ${({ error }) => (error ? `inset 0px -2px 0px ${theme.ERROR}` : '')};

    :focus-visible {
      outline: none !important;
    }
  }
  ::placeholder {
    color: ${theme.PRIMARYGREYCOLOR} !important;
  }
`
export const OtpContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-height: 50px;
`
export const ResendText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  color: ${theme.PRIMARYCOLOR};
  span {
    cursor: pointer;
    color: ${theme.PRIMARYCOLOR};
  }
  &.disable-button {
    cursor: not-allowed;
    opacity: 0.5;
  }
`
