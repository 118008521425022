import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const AllBlogsSection = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  @media (max-width: 1400px) {
    flex-wrap: wrap;
    gap: 14px;
  }
`
export const LatestBlogCardSection = styled.div`
  max-width: 400px;
  height: 309px;
  background: ${theme.HPTEXTCOLORSECONDARYCOLORY};
  border-radius: 15px;
  border: 1px solid ${theme.HPPRIMARYBORDERCOLOR};
  @media (max-width: 450px) {
    height: fit-content;
  }
`
export const LatestBlogImageSection = styled.div`
  img {
    width: 400px;
    height: 175px;
    border-radius: 15px 15px 0 0;
    overflow: hidden;
    object-fit: cover;
  }
  @media (max-width: 450px) {
    img {
      width: 350px;
    }
  }
`
export const LatestBlogDetailSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
`
export const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
`
export const DateAndCategorySection = styled.div`
  display: flex;
  gap: 41px;
`
export const LatestBlogDateWrapper = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.HPSECONDARYLIGHTTEXTCOLOR};
`
export const LatestBlogCategoryWrapper = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  color: ${theme.HPPRIMARYCOLOR};
`
export const LatestBlogBlogTitle = styled.div`
  font-weight: 700;
  font-size: 16x;
  line-height: 24px;
  color: ${theme.HPTEXTCOLORLIGHT};
`
export const LatestBlogReadMoreText = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 49.2px;
  color: ${theme.HPSECONDARYCOLOR};
  text-decoration: underline;
  cursor: pointer;
`
