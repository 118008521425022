import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const BannerWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: ${theme.HPSECONDARYCOLOR};
    font-size: 10px;
  }
  .slick-dots li button:before {
    font-size: 10px;
  }
  .slick-dots {
    bottom: -17px !important;
  }
`

export const BannerItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  width: 100%;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 1500px) {
    height: 100vh;
  }
  @media (max-width: 1450px) {
    height: 84vh;
  }
  @media (max-width: 1300px) {
    height: 100%;
  }
  @media (max-width: 768px) {
    height: 40vh;
  }

  @media (max-width: 480px) {
    height: 30vh;
  }
`
export const BannerImage = styled.img.attrs((props) => ({
  src: props.srcDesktop,
}))`
  width: 100%;

  @media (max-width: 450px) {
    content: url(${(props) => props.srcMobile});
  }
`
