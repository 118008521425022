import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const CancelOrderModalWrapper = styled.div`
  max-width: 700px;
  min-width: 650px;
  width: 100%;
  background: ${theme.SECONDARYTEXTCOLOR};
  @media (max-width: 767px) {
    min-width: unset;
    max-width: unset;
  }
`
export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CancellationWrapper = styled.div`
  padding: 12px 0;
  border-bottom: 1px solid ${theme.SECONDARYBORDERCOLOR};
`
export const Subtitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: ${theme.PRIMARYGREYCOLOR};
  margin: 0;
  @media (max-width: 767px) {
    padding-left: 5px;
  }
`
export const ProductCancellationWrapper = styled.div`
  max-height: 250px;
  overflow: auto;
`
export const ProductListWrapper = styled.div`
  padding: 4px 8px;
`
export const ListWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
`
export const ImageWrapper = styled.div`
  max-width: 100px;
  max-height: 80px;
  img {
    width: 100%;
    height: 100%;
  }
`
export const DetailWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
`
export const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
export const ProductName = styled.div`
  font-size: 16px;
  text-align: left;
`
export const CountWrapper = styled.div`
  font-size: 16px;
  text-align: left;
`
export const ProductDetailWrapper = styled.div`
  width: 100%;
`
export const PriceWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const CancelledProductWrapper = styled.div``
export const CardWrapper = styled.div`
  width: 90px;
  height: 30px;
  background: ${theme.PRIMARYBORDERCOLORLIGHT};
  border: 1px solid ${theme.DARKBLUE};
  color: ${theme.DARKBLUE};
  border-radius: 36px;
  display: flex;
  align-items: center;
`
export const QuantityWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const CancellationReasonWrapper = styled.div`
  .dropdown-menu.cancel-dropdown {
    width: 100%;
    max-height: 97px;
    overflow: auto;
    margin: 144px 0 !important;
  }
`
export const ReasonTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: ${theme.PRIMARYGREYCOLOR};
  margin: 5px;
`
export const SelectReasonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 6px 10px;
  background-color: transparent;
  border: 1px solid ${theme.INPUTCOLOR};
  border-radius: 10px;
  margin-top: 4px;
`
export const PlaceholderText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: ${theme.PRIMARYGREYCOLOR};
  margin: 0;
`
export const RefundWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
  .refund-checkbox {
    border: none;
    background: transparent;
    width: 25px;
  }
`
export const RefundText = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: ${theme.PRIMARYGREYCOLOR};
`
export const ButtonWrapper = styled.div`
  padding: 16px 0 0 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  button {
    text-transform: none;
    max-width: 200px;
  }
`
