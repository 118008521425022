import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 40px 100px 40px 100px;
  .MuiTabPanel-root {
    padding: 28px 50px !important;
  }
  @media (max-width: 767px) {
    margin: 60px 24px 0;
    gap: 24px;
    .MuiTabPanel-root {
      padding: 16px 0;
    }
  }
  @media (max-width: 500px) {
    margin: 0 24px 0;
    .MuiTabPanel-root {
      padding: 28px 28px 18px 70px !important;
    }
  }
`
export const TabContainer = styled.div`
  width: 100%;
  overflow-x: hidden;
  @media (max-width: 768px) {
    overflow-x: auto;
    /* -webkit-overflow-scrolling: touch; Smooth scrolling on mobile */
  }

  /* Hide scrollbar for WebKit browsers */
  &::-webkit-scrollbar {
    display: none;
  }
`
export const MainHeading = styled.div`
  font-weight: 700;
  font-size: 42px;
  line-height: 51.2px;
  color: ${theme.HPPRIMARYTEXTCOLORDARK};
  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 29.26px;
  }
`
