import moment from 'moment'
import { useHistory } from 'react-router-dom'
import {
  AllBlogsSection,
  LatestBlogCardSection,
  LatestBlogImageSection,
  LatestBlogDetailSection,
  ContentSection,
  DateAndCategorySection,
  LatestBlogDateWrapper,
  LatestBlogCategoryWrapper,
  LatestBlogBlogTitle,
  LatestBlogReadMoreText,
} from 'styles/hpLayout/categoryBlogs'

const CategoryWiseBlogs = ({ data }) => {
  const history = useHistory()
  const handleClick = (id) => {
    history.push(`/blog/${id}`)
  }

  return (
    <AllBlogsSection>
      {data?.map((item, index) => (
        <LatestBlogCardSection key={index}>
          <LatestBlogImageSection>
            <img src={item?.bannerImage} />
          </LatestBlogImageSection>
          <LatestBlogDetailSection>
            <ContentSection>
              <DateAndCategorySection>
                <LatestBlogDateWrapper>{moment(item?.updatedAt).format('MMMM Do, YYYY')}</LatestBlogDateWrapper>
                <LatestBlogCategoryWrapper>{item?.category}</LatestBlogCategoryWrapper>
              </DateAndCategorySection>
              <LatestBlogBlogTitle>{item?.title}</LatestBlogBlogTitle>
              <LatestBlogReadMoreText onClick={() => handleClick(item?.id)}>Read More</LatestBlogReadMoreText>
            </ContentSection>
          </LatestBlogDetailSection>
        </LatestBlogCardSection>
      ))}
    </AllBlogsSection>
  )
}

export default CategoryWiseBlogs
