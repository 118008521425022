import React, { useContext, useState, useEffect, useRef } from 'react'
import CrossIcon from '../../shared/svg/cross-icon'
import { ONDC_COLORS } from '../../shared/colors'
import styles from '../../../styles/search-product-modal/searchProductModal.module.scss'
import productCartStyles from '../../../styles/products/productCard.module.scss'
import ErrorMessage from '../../shared/error-message/errorMessage'
import { toast_actions, toast_types } from '../../shared/toast/utils/toast'
import { ToastContext } from '../../../context/toastContext'
import useCancellablePromise from '../../../api/cancelRequest'
import { getSelectCall, postCheckoutCall } from '../../../api/axios'
import Checkbox from '../../shared/checkbox/checkbox'
// import Dropdown from '../../shared/dropdown/dropdown'
import { ISSUE_TYPES } from '../../../constants/issue-types'
import Input from '../../shared/input/input'
import validator from 'validator'
import { getValueFromCookie } from '../../../utils/cookies'
import Subtract from '../../shared/svg/subtract'
import Add from '../../shared/svg/add'
// import Loading from '../../shared/loading/loading'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ProductImage from 'assets/images/no_image_found.png'
import { Button, FormControl, MenuItem, Select, Typography } from '@mui/material'

import {
  RaiseIssueModalWrapper,
  SubTitle,
  MainWrapper,
  ProductContainer,
  IssueWrapper,
  ProductWrapper,
  ProductImageWrapper,
  ProductDetailWrapper,
  ProductNameWrapper,
  QuantityWrapper,
  ProductQuantityWrapper,
  ProductPriceWrapper,
  ProductPrice,
  IssueReasonWrapper,
  ReasonTitle,
  // SelectReasonWrapper,
  // PlaceholderText,
  SupportedImageFormats,
  IssueImageWrapper,
  UploadProductImageWrapper,
  ButtonWrapper,
  MinusWrapper,
  PlusWrapper,
  MobileProductPrice,
} from 'styles/RaiseIssueModal'
import usePost from 'hooks/usePost'
import { Wrapper } from 'styles/ReturnOrderModal'
import CustomButton from 'components/customButton'

export default function IssueOrderModal({
  billing_address,
  transaction_id,
  fulfillments,
  bpp_id,
  bpp_uri,
  order_id,
  order_status,
  partailsIssueProductList = [],
  onClose,
  onSuccess,
  quantity,
  domain,
  issueData,
  // amount,
}) {
  // STATES
  const [inlineError, setInlineError] = useState({
    selected_id_error: '',
    subcategory_error: '',
    shortDescription_error: '',
    longDescription_error: '',
    image_error: '',
  })
  const [loading, setLoading] = useState(false)
  const [selectedIssueSubcategory, setSelectedIssueSubcategory] = useState()
  const [shortDescription, setShortDescription] = useState('')
  const [longDescription, setLongDescription] = useState('')
  const [email, setEmail] = useState(billing_address.email)
  const [baseImage, setBaseImage] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [orderQty, setOrderQty] = useState([])
  const [imageNames, setImageNames] = useState([])
  const [finalCategory, setFinalCategory] = useState()
  imageNames
  const { mutateAsync } = usePost()
  const AllCategory = ISSUE_TYPES.map((item) => {
    return item.subCategory.map((subcategoryItem) => {
      return {
        ...subcategoryItem,
        category: item.value,
      }
    })
  }).flat()
  const extractKeyValuePairs = (arr) => {
    const result = []

    if (Array.isArray(arr)) {
      arr?.forEach((item) => {
        if (item.sub_category && item.issue_status) {
          result.push({
            sub_category: item.sub_category,
            issue_status: item.issue_status,
          })
        }
      })
    }

    const res = []

    const openSubCategories = result
      ?.filter((status) => status?.issue_status === 'Open')
      ?.map((status) => status?.sub_category)

    const closedItems = AllCategory.filter((category) => !openSubCategories?.includes(category.enums))
    res.push(...closedItems)
    setFinalCategory(res)
  }

  useEffect(() => {
    extractKeyValuePairs(issueData?.issues)
  }, [])

  // REFS
  const cancelPartialEventSourceResponseRef = useRef(null)
  const eventTimeOutRef = useRef([])

  // CONTEXT
  const dispatch = useContext(ToastContext)

  // HOOKS
  const { cancellablePromise } = useCancellablePromise()

  // use this function to dispatch error
  function dispatchToast(message, type) {
    dispatch({
      type: toast_actions.ADD_TOAST,
      payload: {
        id: Math.floor(Math.random() * 100),
        type,
        message,
      },
    })
  }

  // use this api to raise an order issue
  async function handleRaiseOrderIssue() {
    const allCheckPassed = [
      checkSubcategory(),
      checkIsOrderSelected(),
      checkShortDescription(),
      checkLongDescription(),
      checkImages(),
    ].every(Boolean)

    if (!allCheckPassed) return
    cancelPartialEventSourceResponseRef.current = []
    setLoading(true)
    try {
      const createdDateTime = new Date().toISOString()
      const data = await cancellablePromise(
        postCheckoutCall('/issueApis/v1/issue', {
          context: {
            transaction_id,
            domain,
          },
          message: {
            issue: {
              category: selectedIssueSubcategory.category.toUpperCase(),
              sub_category: selectedIssueSubcategory.enums,
              bppId: bpp_id,
              bpp_uri,
              created_at: createdDateTime,
              updated_at: createdDateTime,
              complainant_info: {
                person: {
                  name: billing_address.name,
                },
                contact: {
                  phone: billing_address.phone,
                  // email: email === '' ? user?.email : email,
                  // email: email === '' ? user?.email : billing_address?.email,
                  email: email === '' ? billing_address?.email : email,
                },
              },
              description: {
                short_desc: shortDescription,
                long_desc: longDescription,
                additional_desc: {
                  url: 'https://buyerapp.com/additonal-details/desc.txt',
                  content_type: 'text/plain',
                },
                images: baseImage,
              },
              order_details: {
                id: order_id,
                state: order_status,
                items: selectedIds,
                fulfillments,
                provider_id: selectedIds?.[0]?.product.provider_details?.id,
              },
              issue_actions: {
                complainant_actions: [],
                respondent_actions: [],
              },
            },
          },
        }),
      )

      //Error handling workflow eg, NACK
      if (data.message && data.message.ack.status === 'NACK') {
        setLoading(false)
        dispatchToast('Something went wrong', toast_types.error)
      } else {
        const token = getValueFromCookie('token')
        let header = {
          headers: {
            ...(token && {
              Authorization: `Bearer ${token}`,
            }),
          },
        }

        try {
          const res = await cancellablePromise(
            getSelectCall(`/issueApis/v2/on_issue?messageId=${data.context?.message_id}`),
            header,
          )
          setLoading(false)
          onSuccess()
          return res
          // cancelPartialEventSourceResponseRef.current = [...cancelPartialEventSourceResponseRef.current, data]
        } catch (err) {
          onClose()
          setLoading(false)
          onSuccess()
          dispatchToast(err?.response?.data?.message, toast_types.error)
          eventTimeOutRef.current.forEach(({ eventSource, timer }) => {
            eventSource.close()
            clearTimeout(timer)
          })
        }
        // fetchCancelPartialOrderDataThroughEvents(data.context?.message_id, createdDateTime)
      }
    } catch (err) {
      setLoading(false)
      dispatchToast(err?.message, toast_types.error)
    }
  }

  // use this function to fetch cancel product through events
  // eslint-disable-next-line no-unused-vars
  // function fetchCancelPartialOrderDataThroughEvents(message_id, createdDateTime) {
  //   const token = getValueFromCookie('token')
  //   let header = {
  //     headers: {
  //       ...(token && {
  //         Authorization: `Bearer ${token}`,
  //       }),
  //     },
  //   }
  //   let es = new window.EventSourcePolyfill(
  //     `${process.env.REACT_APP_BASE_URL}issueApis/events?messageId=${message_id}`,
  //     header,
  //   )

  //   es.addEventListener('on_issue', (e) => {
  //     if (e?.data) {
  //       const { messageId } = JSON.parse(e.data)
  //       getPartialCancelOrderDetails(messageId, createdDateTime)
  //     } else {
  //       setLoading(false)
  //       onSuccess()
  //     }
  //   })

  //   const timer = setTimeout(() => {
  //     // es.close();
  //     if (cancelPartialEventSourceResponseRef?.current?.length <= 0) {
  //       // dispatchToast(
  //       //   "Cannot proceed with you request now! Please try again",
  //       //   toast_types.error
  //       // );
  //       setLoading(false)
  //       onSuccess()
  //     }
  //   }, SSE_TIMEOUT)

  //   eventTimeOutRef.current = [
  //     ...eventTimeOutRef.current,
  //     {
  //       eventSource: es,
  //       timer,
  //     },
  //   ]
  // }

  // on Issue api
  // async function getPartialCancelOrderDetails(message_id, createdDateTime) {
  //   try {
  //     const data = await cancellablePromise(
  //       getSelectCall(`/issueApis/v1/on_issue?messageId=${message_id}&createdDateTime=${createdDateTime}`),
  //     )
  //     cancelPartialEventSourceResponseRef.current = [...cancelPartialEventSourceResponseRef.current, data]
  //     setLoading(false)
  //     onSuccess()
  //   } catch (err) {
  //     setLoading(false)
  //     onSuccess()
  //     dispatchToast(err?.message, toast_types.error)
  //     eventTimeOutRef.current.forEach(({ eventSource, timer }) => {
  //       eventSource.close()
  //       clearTimeout(timer)
  //     })
  //   }
  // }

  useEffect(() => {
    if (order_status === 'Accepted' || order_status === 'In-progress' || order_status === 'Created') {
      const type = AllCategory.find(({ enums }) => enums === 'FLM02')
      setSelectedIssueSubcategory(type)
    }

    return () => {
      eventTimeOutRef.current.forEach(({ eventSource, timer }) => {
        eventSource.close()
        clearTimeout(timer)
      })
    }
  }, [])

  // function checkShortDescription() {
  //   if (validator.isEmpty(shortDescription.trim())) {
  //     setInlineError((error) => ({
  //       ...error,
  //       shortDescription_error: 'Please enter short description',
  //     }))
  //     return false
  //   }

  //   return true
  // }

  function checkShortDescription() {
    // Maximum allowed characters
    const maxLength = 50

    // Trim the shortDescription string
    const trimmedDescription = shortDescription.trim()

    // Check if the trimmed description is empty
    if (validator.isEmpty(trimmedDescription)) {
      setInlineError((error) => ({
        ...error,
        shortDescription_error: 'Please enter short description',
      }))
      return false
    }

    // Check if the length of the trimmed description exceeds the maximum allowed length
    if (trimmedDescription.length > maxLength) {
      setInlineError((error) => ({
        ...error,
        shortDescription_error: 'Maximum 50 characters alllowed',
      }))
      return false
    }

    return true
  }

  // function checkLongDescription() {
  //   if (validator.isEmpty(longDescription.trim())) {
  //     setInlineError((error) => ({
  //       ...error,
  //       longDescription_error: 'Please enter long description',
  //     }))
  //     return false
  //   }

  //   return true
  // }

  function checkLongDescription() {
    // Maximum allowed characters
    const maxLength = 250

    // Trim the longDescription string
    const trimmedDescription = longDescription.trim()

    // Check if the trimmed description is empty
    if (validator.isEmpty(trimmedDescription)) {
      setInlineError((error) => ({
        ...error,
        longDescription_error: 'Please enter long description',
      }))
      return false
    }

    // Check if the length of the trimmed description exceeds the maximum allowed length
    if (trimmedDescription.length > maxLength) {
      setInlineError((error) => ({
        ...error,
        longDescription_error: 'Maximum 250 characters are allowed',
      }))
      return false
    }

    return true
  }

  // use this function to check if any order is selected
  function checkIsOrderSelected() {
    if (selectedIds?.length <= 0) {
      setInlineError((error) => ({
        ...error,
        selected_id_error: 'Please select item to raise an issue',
      }))
      return false
    }

    return true
  }

  // use this function to check if any reason is selected
  function checkSubcategory() {
    if (!selectedIssueSubcategory) {
      setInlineError((error) => ({
        ...error,
        subcategory_error: 'Please select subcategory',
      }))
      return false
    }

    return true
  }

  // use this function to check if any image is selected
  function checkImages() {
    if (['ITM02', 'ITM03', 'ITM04', 'ITM05', 'FLM04'].includes(selectedIssueSubcategory?.enums) && baseImage <= 0) {
      setInlineError((error) => ({
        ...error,
        image_error: 'Please upload an image file',
      }))
      return false
    }

    return true
  }

  // use this function to check if the provider is already selected
  function isProductSelected(id) {
    return selectedIds.filter(({ id: provider_id }) => provider_id === id).length > 0
  }

  // use this function to add attribute in filter list
  function addProductToCancel(attribute, qty, status) {
    let modifiedAttributes = {
      id: attribute.id,
      fulfillment_status: status,
      // quantity: qty,
      quantity: {
        count: qty,
      },
      product: attribute,
    }
    setSelectedIds([...selectedIds, modifiedAttributes])
  }

  const filteredIssueProduct = partailsIssueProductList.filter(
    (item) =>
      item.fulfillment_status !== 'Return_Initiated' &&
      item.fulfillment_status !== 'Liquidated' &&
      item.fulfillment_status !== 'Cancelled',
  )

  let subtotal = []
  filteredIssueProduct.forEach((product, idx) => {
    const productTotal = product.subtotal * (orderQty[idx] ?? 0)
    subtotal.push(parseFloat(productTotal).toFixed(2))
  })

  // Update total amount whenever quantity changes
  const handleUpdateQty = (qty, idx, pId) => {
    let qtyData = Object.assign([], orderQty)
    qtyData[idx] = qty
    setOrderQty(qtyData)
    updateQtyForSelectedProduct(pId, qty)
  }

  const totalAmount = subtotal

  // use this function to remove the selected attribute from filter
  function removeProductToCancel(attribute) {
    setSelectedIds(selectedIds.filter(({ id }) => id !== attribute.id))
  }

  useEffect(() => {
    if (quantity) {
      setOrderQty(JSON.parse(JSON.stringify(Object.assign(quantity))))
    }
  }, [quantity])

  const uploadImages = async (files) => {
    try {
      const uploadedImages = []
      const imageNames = []

      for (const file of files) {
        const formData = new FormData()
        formData.append('file', file)

        const res = await mutateAsync({
          url: `/clientApis/v2/getSignUrlForUpload/${order_id}`,
          method: 'POST',
          payload: formData,
        })

        if (res && res.success && res.publicUrl) {
          uploadedImages.push(res.publicUrl)
          imageNames.push(file.name)
        }
      }

      // Update both image URLs and names
      setBaseImage([...baseImage, ...uploadedImages])
      setImageNames([...imageNames, ...imageNames])
    } catch (err) {
      dispatchToast('Error uploading images', toast_types.error)
    }
  }

  const onUpdateQty = (qty, idx, pId) => {
    let qtyData = Object.assign([], orderQty)
    qtyData[idx] = qty
    setOrderQty(qtyData)
    updateQtyForSelectedProduct(pId, qty)
  }

  function updateQtyForSelectedProduct(pId, qty) {
    let data = JSON.parse(JSON.stringify(Object.assign([], selectedIds)))
    data = data.map((item) => {
      if (item.id === pId) {
        item.quantity.count = qty
      }

      return item
    })
    setSelectedIds(data)
  }

  const handleCategory = (reasonValue) => {
    const type = AllCategory.find(({ value }) => value.toLowerCase() === reasonValue.toLowerCase())
    setSelectedIssueSubcategory(type)
    setInlineError((error) => ({
      ...error,
      subcategory_error: '',
    }))
  }

  return (
    <div className={styles.overlay}>
      <RaiseIssueModalWrapper className={styles.popup_card}>
        <MainWrapper>
          <SubTitle>
            Choose Items that had a problem<span>*</span>
          </SubTitle>
          <ProductContainer>
            {filteredIssueProduct?.map((product, idx) => {
              if (quantity[idx]?.count === 0) {
                return null // Skip rendering this product if the quantity is 0
              }

              return (
                <IssueWrapper key={idx}>
                  <ProductWrapper>
                    <Checkbox
                      id={product?.id}
                      checked={isProductSelected(product?.id)}
                      disabled={loading}
                      onClick={() => {
                        setInlineError((error) => ({
                          ...error,
                          selected_id_error: '',
                        }))
                        if (isProductSelected(product?.id)) {
                          removeProductToCancel(product)
                          return
                        }

                        addProductToCancel(product, orderQty[idx], product?.fulfillment_status)
                      }}
                    />
                    <ProductImageWrapper>
                      <img
                        src={product?.descriptor?.symbol ? product?.descriptor?.symbol : ProductImage}
                        onError={(e) => {
                          e.target.src =
                            'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                        }}
                      />
                    </ProductImageWrapper>
                    <ProductDetailWrapper>
                      <ProductNameWrapper>{product?.name}</ProductNameWrapper>

                      <ProductQuantityWrapper>QTY: {quantity[idx] ?? '0'}</ProductQuantityWrapper>
                      <MobileProductPrice>₹ {totalAmount[idx]}</MobileProductPrice>
                    </ProductDetailWrapper>
                    {isProductSelected(product?.id) && (
                      <QuantityWrapper>
                        <MinusWrapper
                          className={`${
                            orderQty[idx] > 1 ? productCartStyles.subtract_svg_wrapper : ''
                          } d-flex align-items-center justify-content-center`}
                          onClick={() => {
                            if (orderQty[idx] > 1 && isProductSelected(product?.id)) {
                              handleUpdateQty(orderQty[idx] - 1, idx, product?.id)
                            }
                          }}
                        >
                          {orderQty[idx] > 1 && <Subtract width="13" classes={productCartStyles.subtract_svg_color} />}
                        </MinusWrapper>
                        <div className="d-flex align-items-center justify-content-center">
                          <p className={productCartStyles.quantity_count}>{orderQty[idx] ?? '0'}</p>
                        </div>
                        <PlusWrapper
                          className={`${
                            orderQty[idx] < quantity[idx] ? productCartStyles.add_svg_wrapper : ''
                          } d-flex align-items-center justify-content-center`}
                          onClick={() => {
                            if (orderQty[idx] < quantity[idx] && isProductSelected(product?.id)) {
                              onUpdateQty(orderQty[idx] + 1, idx, product?.id)
                            }
                          }}
                        >
                          {orderQty[idx] < quantity[idx] && (
                            <Add width="13" height="13" classes={productCartStyles.add_svg_color} />
                          )}
                        </PlusWrapper>
                      </QuantityWrapper>
                    )}
                  </ProductWrapper>

                  <ProductPriceWrapper>
                    <ProductPrice>₹ {totalAmount[idx]}</ProductPrice>
                    {/* <ProductPrice>₹ {Number(product?.price?.value)?.toFixed(2)}</ProductPrice> */}
                  </ProductPriceWrapper>
                </IssueWrapper>
              )
            })}
          </ProductContainer>
          {inlineError.selected_id_error && <ErrorMessage>{inlineError.selected_id_error}</ErrorMessage>}
          {order_status === 'Completed' && (
            <IssueReasonWrapper>
              <ReasonTitle className={`${styles.required}`}>Select Issue Subcategory</ReasonTitle>
              <FormControl fullWidth>
                <Select
                  value={selectedIssueSubcategory?.value || ''}
                  onChange={(event) => handleCategory(event.target.value)}
                  IconComponent={() => <ExpandMoreIcon sx={{ color: '#979797' }} />}
                  className="reason-wrapper"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        height: '200px',
                        overflowY: 'scroll',
                        fontSize: '12px',
                        width: '100% !important',
                      },
                    },
                  }}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return (
                        <Wrapper>
                          <Typography variant="caption1">{'Select issue '}</Typography>
                        </Wrapper>
                      )
                    } else {
                      return <Wrapper>{selected}</Wrapper>
                    }
                  }}
                >
                  {finalCategory
                    ?.filter(({ enums }) => enums !== 'FLM02')
                    .map(({ value }) => (
                      <MenuItem
                        key={value}
                        value={value}
                        sx={{
                          fontSize: '12px',
                        }}
                      >
                        {value}
                      </MenuItem>
                    ))}
                </Select>
                {inlineError.subcategory_error && <ErrorMessage>{inlineError.subcategory_error}</ErrorMessage>}
              </FormControl>
            </IssueReasonWrapper>
          )}

          <div>
            {(order_status === 'Accepted' || order_status === 'Created' || order_status === 'In-progress') && (
              <Input label_name="Select Issue Subcategory" disabled type="text" value={'Delay in delivery'} />
            )}
            <Input
              label_name="Short Description"
              type="text"
              placeholder="Enter short description"
              id="shortDes"
              value={shortDescription}
              onChange={(event) => {
                const name = event.target.value
                setShortDescription(name)
                setInlineError((error) => ({
                  ...error,
                  shortDescription_error: '',
                }))
              }}
              required
              has_error={inlineError.shortDescription_error}
            />
            {inlineError.shortDescription_error && <ErrorMessage>{inlineError.shortDescription_error}</ErrorMessage>}
            <Input
              label_name="Long Description"
              type="text"
              placeholder="Enter long description"
              id="longDes"
              value={longDescription}
              onChange={(event) => {
                const name = event.target.value
                setLongDescription(name)
                setInlineError((error) => ({
                  ...error,
                  longDescription_error: '',
                }))
              }}
              required
              has_error={inlineError.longDescription_error}
            />
            {inlineError.longDescription_error && <ErrorMessage>{inlineError.longDescription_error}</ErrorMessage>}
            <Input
              label_name="Email"
              type="text"
              placeholder="Enter Email"
              id="email"
              value={email}
              onChange={(event) => {
                const name = event.target.value
                setEmail(name)
              }}
              required
              disabled
            />
            {/* <Input
              label_name="Images (Maximum 4)"
              type="file"
              id="images"
              accept="image/png,image/jpg,image/jpeg"
              multiple
              onChange={(event) => {
                const files = event.target.files
                if (!files.length) return // No file selected, ignore this change

                const validFiles = Array.from(files).filter((file) => file.size / 1024 <= 2048)

                if (validFiles.length < files.length) {
                  dispatchToast('Some files exceeded the 2MB size limit and were not uploaded', toast_types.error)
                }

                if (validFiles.length) {
                  uploadImages(validFiles)
                  setInlineError((error) => ({
                    ...error,
                    image_error: '',
                  }))
                }
              }}
              required={['ITM02', 'ITM03', 'ITM04', 'ITM05', 'FLM04'].includes(selectedIssueSubcategory?.enums)}
              has_error={inlineError.image_error}
              disabled={baseImage.length === 4}
            /> */}
            <label htmlFor="images" style={{ display: 'inline-block', cursor: 'pointer' }}>
              <Input
                label_name="Images (Maximum 4)"
                type="file"
                id="images"
                accept="image/png,image/jpg,image/jpeg"
                multiple
                onChange={(event) => {
                  const files = event.target.files
                  if (!files.length) return // No file selected, ignore this change

                  const remainingSlots = 4 - baseImage.length // Remaining number of images that can be uploaded
                  const selectedFiles = Array.from(files)

                  // Check for file size first
                  const oversizedFiles = selectedFiles.filter((file) => file.size / 1024 > 5120)
                  if (oversizedFiles.length) {
                    dispatchToast('Some files exceeded the 5MB size limit and were not uploaded', toast_types.error)
                  }

                  // After filtering out oversized files, check for the number of images
                  const validFiles = selectedFiles.filter((file) => file.size / 1024 <= 5120).slice(0, remainingSlots)
                  if (selectedFiles.length > remainingSlots) {
                    dispatchToast(`You can only upload 4 image(s).`, toast_types.error)
                  }

                  if (validFiles.length) {
                    uploadImages(validFiles)
                    setInlineError((error) => ({
                      ...error,
                      image_error: '',
                    }))
                  }
                }}
                required={['ITM02', 'ITM03', 'ITM04', 'ITM05', 'FLM04', 'FLM07'].includes(
                  selectedIssueSubcategory?.enums,
                )}
                has_error={inlineError.image_error}
                disabled={baseImage.length === 4}
                style={{ display: 'none' }} // Hide the input itself
              />
              <Button component="span" className="upload-image" disabled={baseImage.length === 4}>
                Upload Images
              </Button>
            </label>
            {inlineError.image_error && <ErrorMessage>{inlineError.image_error}</ErrorMessage>}
            <SupportedImageFormats>
              Supported Formats: jpg, jpeg, png. You can upload image upto 5 mb.
            </SupportedImageFormats>
          </div>
          <IssueImageWrapper>
            {baseImage?.map((image, index) => {
              return (
                <UploadProductImageWrapper key={index}>
                  <CrossIcon
                    color={ONDC_COLORS.SECONDARYCOLOR}
                    onClick={() => {
                      setBaseImage(baseImage.filter((item) => item !== image))
                      const inputElement = document.getElementById('images')
                      if (inputElement) {
                        inputElement.value = '' // Reset the input value to clear the filename
                      }
                    }}
                  />
                  <img
                    src={image ? image : ProductImage}
                    onError={(e) => {
                      e.target.src =
                        'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                    }}
                  />
                </UploadProductImageWrapper>
              )
            })}
          </IssueImageWrapper>
        </MainWrapper>
        <ButtonWrapper>
          <CustomButton
            variant="outline1"
            disabled={loading}
            label={'Cancel'}
            onClick={() => {
              onClose()
            }}
          />
          <CustomButton
            variant="contained1"
            disabled={loading}
            label={'Confirm'}
            onClick={() => {
              handleRaiseOrderIssue()
            }}
          />
        </ButtonWrapper>
      </RaiseIssueModalWrapper>
    </div>
  )
}
